const jsonLdInitial = {
  "@context": "http://schema.org",
  "@type": "Person",
  name: "Joseph",
  url: "https://www.xceltrip.com",
  sameAs: [
    "https://www.facebook.com/XcelTripGo",
    "https://twitter.com/xceltrip",
    "https://www.linkedin.com/company/xceltrip",
    "https://www.instagram.com/xceltripofficial",
    "https://in.pinterest.com/xceltrip",
  ],
};

const jsonLdTwo = {
  "@context": "http://schema.org",
  "@type": "Corporation",
  name: "Book 2.3 million+ Hotels and 450+ Flights using Bitcoin, Ethereum, XcelPay, USDT and more at XcelTrip:Crypto friendly travel booking platfrom.",
  url: "https://www.xceltrip.com",
  sameAs: [
    "https://twitter.com/xceltrip",
    "https://www.facebook.com/XcelTripGo",
    "https://www.linkedin.com/company/xceltrip",
    "https://www.instagram.com/xceltripofficial",
    "https://in.pinterest.com/xceltrip",
  ],
  location: {
    "@type": "Place",
    name: "Xceltrip",
    url: "https://www.xceltrip.com",
    sameAs: [
      "https://twitter.com/xceltrip",
      "https://www.facebook.com/XcelTripGo",
      "https://www.linkedin.com/company/xceltrip",
      "https://www.instagram.com/xceltripofficial",
      "https://in.pinterest.com/xceltrip",
    ],
  },
};
const jsonLdThree = {
  "@context": "http://schema.org",
  "@type": "WebSite",
  name: "Book 2.3 million+ Hotels and 450+ Flights using Bitcoin, Ethereum, XcelPay, USDT and more at XcelTrip:Crypto friendly travel booking platfrom.",
  description:
    "XcelTrip is a functional usecase for XcelToken. Book over 800,000 Hotels and Flight Tickets at 330+ Airlines around the world using Crypto, Bitcoin, BNB & Ethereum.",
  url: "https://www.xceltrip.com",
  image: "https://www.xceltrip.com/og_image/xceltripOG.jpeg",
  sameAs: [
    "https://twitter.com/xceltrip",
    "https://www.facebook.com/XcelTripGo",
    "https://www.linkedin.com/company/xceltrip",
    "https://www.instagram.com/xceltripofficial",
    "https://in.pinterest.com/xceltrip",
  ],
  copyrightHolder: {
    "@type": "Corporation",
    name: "Xceltrip",
    url: "https://www.xceltrip.com",
    sameAs: [
      "https://twitter.com/xceltrip",
      "https://www.facebook.com/XcelTripGo",
      "https://www.linkedin.com/company/xceltrip",
      "https://www.instagram.com/xceltripofficial",
      "https://in.pinterest.com/xceltrip",
    ],
    location: {
      "@type": "Place",
      name: "Xceltrip",
      url: "https://www.xceltrip.com",
      sameAs: [
        "https://twitter.com/xceltrip",
        "https://www.facebook.com/XcelTripGo",
        "https://www.linkedin.com/company/xceltrip",
        "https://www.instagram.com/xceltripofficial",
        "https://in.pinterest.com/xceltrip",
      ],
    },
  },
  author: {
    "@type": "Corporation",
    name: "Book 2.3 million+ Hotels and 450+ Flights using Bitcoin, Ethereum, XcelPay, USDT and more at XcelTrip:Crypto friendly travel booking platfrom.",
    url: "https://www.xceltrip.com",
    sameAs: [
      "https://twitter.com/xceltrip",
      "https://www.facebook.com/XcelTripGo",
      "https://www.linkedin.com/company/xceltrip",
      "https://www.instagram.com/xceltripofficial",
      "https://in.pinterest.com/xceltrip",
    ],
    location: {
      "@type": "Place",
      name: "Xceltrip",
      url: "https://www.xceltrip.com",
      sameAs: [
        "https://twitter.com/xceltrip",
        "https://www.facebook.com/XcelTripGo",
        "https://www.linkedin.com/company/xceltrip",
        "https://www.instagram.com/xceltripofficial",
        "https://in.pinterest.com/xceltrip",
      ],
    },
  },
  creator: {
    "@type": "Organization",
    name: "Xceltrip",
    alternateName: "XCELTRIP",
    location: {
      "@type": "Place",
      name: "Xceltrip",
      alternateName: "XCELTRIP",
    },
  },
};

const jsonLdFour = {
  "@context": "http://schema.org",
  "@type": "WebPage",
  name: "Book 2.3 million+ Hotels and 450+ Flights using Bitcoin, Ethereum, XcelPay, USDT and more at XcelTrip:Crypto friendly travel booking platfrom.",
  description:
    "Book 2.3 million+ Hotels and 450+ Flights using Bitcoin, Ethereum, XcelPay, USDT and more at XcelTrip:Crypto friendly travel booking platfrom.",
  image: {
    "@type": "ImageObject",
    url: "https://www.xceltrip.com/og_image/xceltripOG.jpeg",
    width: "862",
    height: "252",
  },
  url: "https://www.xceltrip.com",
  mainEntityOfPage: "https://www.xceltrip.com",
  inLanguage: "en_us",
  headline: "XcelTrip:Travel With Crypto",
  keywords:
    "book hotel and flight with crypto, book hotel and flight using bitcoin, buy plane tickets with bitcoin, travel with crypto, travel by bitcoin",
  dateCreated: "2017-10-06T22:11:03+0000",
  dateModified: "2017-07-11T18:02:16+0000",
  datePublished: "2018-08-08T21:34:26+0000",
  copyrightYear: "2018",
  author: {
    "@type": "Corporation",
    name: "Book 2.3 million+ Hotels and 450+ Flights using Bitcoin, Ethereum, XcelPay, USDT and more at XcelTrip:Crypto friendly travel booking platfrom.",
    url: "https://www.xceltrip.com",
    sameAs: [
      "https://twitter.com/xceltrip",
      "https://www.facebook.com/XcelTripGo",
      "https://www.linkedin.com/company/xceltrip",
      "https://www.instagram.com/xceltripofficial",
      "https://in.pinterest.com/xceltrip",
    ],
    location: {
      "@type": "Place",
      name: "Xceltrip",
      url: "https://www.xceltrip.com",
      sameAs: [
        "https://twitter.com/xceltrip",
        "https://www.facebook.com/XcelTripGo",
        "https://www.linkedin.com/company/xceltrip",
        "https://www.instagram.com/xceltripofficial",
        "https://in.pinterest.com/xceltrip",
      ],
    },
  },
  publisher: {
    "@type": "Corporation",
    name: "Book 2.3 million+ Hotels and 450+ Flights using Bitcoin, Ethereum, XcelPay, USDT and more at XcelTrip:Crypto friendly travel booking platfrom.",
    url: "https://www.xceltrip.com",
    sameAs: [
      "https://twitter.com/xceltrip",
      "https://www.facebook.com/XcelTripGo",
      "https://www.linkedin.com/company/xceltrip",
      "https://www.instagram.com/xceltripofficial",
      "https://in.pinterest.com/xceltrip",
    ],
    location: {
      "@type": "Place",
      name: "Xceltrip",
      url: "https://www.xceltrip.com",
      sameAs: [
        "https://twitter.com/xceltrip",
        "https://www.facebook.com/XcelTripGo",
        "https://www.linkedin.com/company/xceltrip",
        "https://www.instagram.com/xceltripofficial",
        "https://in.pinterest.com/xceltrip",
      ],
    },
  },
  copyrightHolder: {
    "@type": "Corporation",
    name: "Book 2.3 million+ Hotels and 450+ Flights using Bitcoin, Ethereum, XcelPay, USDT and more at XcelTrip:Crypto friendly travel booking platfrom.",
    url: "https://www.xceltrip.com",
    sameAs: [
      "https://twitter.com/xceltrip",
      "https://www.facebook.com/XcelTripGo",
      "https://www.linkedin.com/company/xceltrip",
      "https://www.instagram.com/xceltripofficial",
      "https://in.pinterest.com/xceltrip",
    ],
    location: {
      "@type": "Place",
      name: "Book 2.3 million+ Hotels and 450+ Flights using Bitcoin, Ethereum, XcelPay, USDT and more at XcelTrip:Crypto friendly travel booking platfrom.",
      url: "https://www.xceltrip.com",
      sameAs: [
        "https://twitter.com/xceltrip",
        "https://www.facebook.com/XcelTripGo",
        "https://www.linkedin.com/company/xceltrip",
        "https://www.instagram.com/xceltripofficial",
        "https://in.pinterest.com/xceltrip",
      ],
    },
  },
  breadcrumb: {
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        item: {
          "@id": "https://www.xceltrip.com",
          name: "Homepage",
        },
      },
    ],
  },
};

const jsonLdFive = {
  "@context": "http://schema.org",
  "@type": "BreadcrumbList",
  itemListElement: [
    {
      "@type": "ListItem",
      position: "1",
      item: {
        "@id": "https://www.xceltrip.com",
        name: "Homepage",
      },
    },
  ],
};
export { jsonLdInitial, jsonLdTwo, jsonLdThree, jsonLdFour, jsonLdFive };
