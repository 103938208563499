/* eslint-disable no-restricted-globals */
/* eslint-disable default-case */
/* eslint-disable no-restricted-syntax */
// import isEqual from "lodash/isEqual";

export function onlyAlphanumeric(value: string) {
  return !!/^[a-zA-Z0-9]*$/.test(value);
}
export function isLocation(value: string) {
  return !!/^[a-zA-Z0-9- _,.]*$/.test(value);
}
export function onlyNumber(value: string) {
  return !!/^[0-9]*$/.test(value);
}
export function numberWithDecimal(value: string) {
  return !!/^[0-9.]*$/.test(value);
}
export function onlyAlphabet(value: string) {
  return !!/^[a-zA-Z]*$/.test(value);
}

export function zipCode(value: string) {
  return !!/^[0-9A-Za-z]{0,9}(?:-[0-9A-Za-z]{0,9})?$/.test(value);
}

export function regexForMerchantName(value: string) {
  return !!/^[a-zA-Z0-9- ]*$/.test(value);
}
export function fullName(value: string) {
  return !!/^[a-zA-Z ]*$/.test(value);
}
export function decimalNumber(value: string) {
  return !!/^\d+(?:\.\d{1,4})?$/.test(value);
}
export function decimalNumberWithDotMandatory(value: string) {
  return !!/^[0-9](?=[^.]*\.[^.]*$)[0-9.]*[0-9]$/.test(value);
}
export function isEmail(value: any) {
  return !!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    String(value).trim().toLowerCase()
  );
}
export function isPassword(
  value: string | any,
  maxLength: number,
  minlength: number
) {
  return (
    /(?=.*[a-zA-Z])/.test(value) &&
    /(?=.*[!@#$%^&*])/.test(value) &&
    /(?=.*[0-9])/.test(value) &&
    value.length > minlength &&
    value.length < maxLength
  );
}
export function testHtmlTags(value: string) {
  return !/(<\w*)((\s\/>)|(.*<\/\w*>))/.test(value);
}

export function linkStartWithHttp(value: string) {
  return /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'*+,;=.]+$/.test(
    value
  );
}

export function isPhoneNumber(value: string) {
  return !!/^[0-9-+, ]*$/.test(value) && /^.{6,}$/.test(value);
}
export function lengthValidation(
  value: string | number | any[],
  maxLength: number
) {
  return value.length ? value.length <= maxLength : value <= maxLength;
}

export function isNepaliMobileNumber(value: string) {
  return /^(9[876])\d{8}$/.test(value);
}

// Eg. 98XXXXXXXX,98XXXXXXXX
export function validatMultipleMobileNumbers(number: string) {
  return /^(\d{10}(,\d{10})*)?$/.test(number);
}

export function validateHours(date: string) {
  const value = typeof date === "string" ? new Date(Date.parse(date)) : date;
  return !(
    value.getDate() === new Date().getDate() &&
    new Date().getHours() > value.getHours()
  );
}

export function validateMins(date: string) {
  const value = typeof date === "string" ? new Date(Date.parse(date)) : date;
  return !((d = new Date()) =>
    value.getDate() === d.getDate() &&
    d.getHours() === value.getHours() &&
    d.getMinutes() > value.getMinutes())();
}

export function trimSpace(data: string) {
  const wsRegex = /^\s+|\s+$/g; // Change this line
  return typeof data === "string" ? data.replace(wsRegex, "") : data;
}

export function accountNumber(
  value: { toString: () => any },
  minLength: number
) {
  const data = value.toString();
  return !!/^[a-zA-Z0-9]*$/.test(data) && data.length >= minLength;
}

export function clipper(data: string, length = 35) {
  return data.length > length ? `${data.substring(0, length)}....` : data;
}

/* export function isModifydata(data, selectorData) {
  const newData = data && JSON.parse(JSON.stringify(data));
  const newSelectorData =
    selectorData && JSON.parse(JSON.stringify(selectorData));
  return (
    (data &&
      selectorData &&
      trimAll(newData, newData) ===
        trimAll(newSelectorData, newSelectorData)) ||
    isEqual(
      Object.assign({}, trimAll(newData, newData)),
      trimAll(newSelectorData, newSelectorData)
    ) ||
    false
  );
} */

export const commaFormatter = (value: any) => {
  if (Number(value) >= 1000000) {
    return `${Math.round(Number(value) / 1000000)}M`;
  }
  return Number(value).toLocaleString("en-IN");
};

// export const dateFormatter = (
//   date: string | string[] | any,
//   format = "m/d/y"
// ) => {
//   if (
//     (date && typeof date === "object") ||
//     (date && typeof date === "string" && !date.includes("-"))
//   ) {
//     switch (format) {
//       case "m/d/y": // 7/14/2020
//         return new Date(Number(date)).toLocaleDateString("en-us");

//       case "d m y": // 14 JUL 2020
//         return new Date(Number(date)).toLocaleDateString("US", {
//           year: "numeric",
//           month: "short",
//           day: "numeric",
//         });

//       case "y-m-d": // 2020-07-14
//         return new Date(Number(date)).toLocaleDateString("en-CA");
//       case "w-d-m-y": // Thur, 20 Dec 2023
//         return new Date(Number(date)).toLocaleDateString("en-CA", {
//           weekday: "short",
//           year: "numeric",
//           month: "short",
//           day: "numeric",
//         });
//       case "y-m-d hh:mm:ss": // 2021-04-12 16:56:00
//         return new Date(Number(date))
//           .toLocaleDateString("en-CA", {
//             hour12: false,
//             day: "2-digit",
//             month: "2-digit",
//             year: "numeric",
//             hour: "2-digit",
//             minute: "2-digit",
//           })
//           .replace(",", "");
//       // case 'm-d-y': //09-07-2022
//       //   return new Date(Number(date)).toLocaleDateString("en", {
//       //     year: "numeric",
//       //     month: '2-digit',
//       //     day: "2-digit",
//       //   }).replaceAll('/', '-');
//       case "am": //03:00:00 AM || PM
//         return new Date(Number(date)).toLocaleTimeString("en-US");
//       case "hh:mm:ss": //03:00:00
//         return new Date(Number(date)).toLocaleTimeString("it-IT");
//     }
//   } else return date;
//   return null;
// };

const passwordSpecialCharacterAndDigitRegex = "^(?=.*[0-9])(?=.*\\W).*$"; // atleast one special character, at least one digit
const specialCharacterRegex = "^(?=.*\\W).*$"; // atleast one special character
const digitRegex = "^(?=.*[0-9]).*$"; // atleast one digit
const lowerCase = "^(?=.*[a-z]).*$";
const upperCase = "^(?=.*[A-Z]).*$";

export const validators = {
  passwordValidator: (password: string) => {
    if (password && !!password.match(passwordSpecialCharacterAndDigitRegex)) {
      return true;
    }
    return false;
  },
  specialCharacterValidator: (password: string) => {
    if (password && !!password.match(specialCharacterRegex)) {
      return true;
    }
    return false;
  },

  onlylowerCase: (password: string) => {
    if (password && !!password.match(lowerCase)) {
      return true;
    }
    return false;
  },

  onlyUpperCase: (password: string) => {
    if (password && !!password.match(upperCase)) {
      return true;
    }
    return false;
  },

  digitValidator: (password: string) => {
    if (password && !!password.match(digitRegex)) return true;
    return false;
  },
};

/* 

*/
