import { loginSaga } from "./saga";
import React, { useState, useEffect, useContext } from "react";
import { isEmail } from "utils/validation";
import LoginForm from "./components/loginForm";
import { useSelector, useDispatch } from "react-redux";
import { selectError, selectLoading, selectResponseMessage } from "./selectors";
import { sliceKey, reducer, loginActions } from "./slice";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import ForgotPassword from "./ForgotPassword";
import PasswordSet from "./PasswordSetForm";
import TwoFactorAuth from "./2Fa";
import Context from "contexts/context";

declare global {
  interface Window {
    grecaptcha: any;
    recaptcha: any;
  }
}

const LoginPage = (props: any) => {
  const {
    showDialog,
  }: {
    showDialog: any;
  } = useContext(Context);
  const {
    email = "",
    addClassName,
    // showDialog,
    showForgotPassword,
    showPasswordSetForm = false,
    showTwoFactorAuth,
    userName = "",
  } = props;
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: loginSaga });

  const isLoading = useSelector(selectLoading);
  const errorResponse = useSelector(selectError);
  const responseMessage = useSelector(selectResponseMessage);

  const dispatch = useDispatch();
  const recaptchaRef = React.createRef<any>();

  const initialState = {
    username: "",
    password: "",
    reCaptcha: "",
  };
  const [data, setdata] = useState<any>(initialState);
  const [errors, seterrors] = useState<any>({});
  const [isHomePage, setisHomePage] = useState(false);
  useEffect(() => {
    if (
      window.location.pathname === "/" ||
      window.location.pathname === "/login"
    ) {
      setisHomePage(!isHomePage);
    }
    if (userName) {
      setdata({ ...data, username: userName });
    }
    return () => {
      dispatch(loginActions.clearLoginState());
    };
  }, []);

  React.useEffect(() => {
    if (errorResponse) {
      recaptchaRef?.current?.reset();
      setdata({ ...data, reCaptcha: "" });
      seterrors({ ...errors, errResp: errorResponse });
    }
  }, [errorResponse]);

  const handleChange = (event: any) => {
    event.preventDefault();
    const { name, value } = event.target;
    delete errors[name];
    if (errors.errResp) delete errors["errResp"];
    setdata({ ...data, [name]: value });
  };
  const validation = () => {
    let err: any = {};
    if (!data.username) err.username = "Enter the email";
    if (!data.password) err.password = "Enter the password";
    if (!isEmail(data.username)) err.username = "Invalid email";
    if (!data.reCaptcha) err.reCaptcha = "Check this one";

    return err;
  };
  const handleSubmit = (event: any) => {
    event.preventDefault();
    const err = validation();
    seterrors(err);
    if (Object.keys(data).length === 0) return;
    Object.keys(data)?.forEach((k) => (data[k] = data[k]?.trim()));
    if (Object.keys(err).length === 0) {
      dispatch(loginActions.loginRequest(data));
      // recaptchaRef.current.reset();
      // setdata({ ...data, reCaptcha: "" });
    }
  };
  const handleRecaptcha = (value: any) => {
    if (errors.reCaptcha) delete errors["reCaptcha"];
    setdata({ ...data, reCaptcha: value });
  };
  const handleGoogleLogin = (accessToken: any) => {
    dispatch(
      loginActions.loginRequest({ token: accessToken, socialLogin: true })
    );
  };
  const handleToggle = () => {
    // setshowForgotPassForm(!showForgotPassForm);
    showDialog({
      title: "Forgot Password",
      showModal: true,
      mapComp: "forgotPassword",
      addClassName: "reactmodal-modify-2",
    });
  };
  const handleForgotPassword = (value: any) => {
    dispatch(loginActions.forgotPasswordReq(value));
  };
  const handleSetPassword = (payload: any) => {
    dispatch(loginActions.setPasswordReq(payload));
  };
  return (
    <React.Fragment>
      {showForgotPassword && (
        <ForgotPassword
          error={errorResponse}
          isRequesting={isLoading}
          responseMessage={responseMessage}
          forgotPassword={handleForgotPassword}
        />
      )}
      {!showForgotPassword && !showPasswordSetForm && !showTwoFactorAuth && (
        <LoginForm
          data={data}
          errors={errors}
          seterrors={seterrors}
          isLoading={isLoading}
          isHomePage={isHomePage}
          recaptchaRef={recaptchaRef}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          addClassName={addClassName}
          handleRecaptcha={handleRecaptcha}
          handleForgotPassword={handleToggle}
          handleGoogleLogin={handleGoogleLogin}
        />
      )}
      {showPasswordSetForm && (
        <PasswordSet
          email={email}
          isRequesting={isLoading}
          error={errorResponse}
          submitData={handleSetPassword}
        />
      )}
      {showTwoFactorAuth && (
        <TwoFactorAuth
          isLoading={isLoading}
          serverResp={errorResponse ?? ""}
          verifyTfa={(payload: any) =>
            dispatch(loginActions.verifyTfaCodeReq(payload))
          }
        />
      )}
    </React.Fragment>
  );
};

export default LoginPage;
