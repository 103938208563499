import { useState } from "react";
import AlertMessage from "comp/Alert";
import { Button } from "flowbite-react";
import Spinners from "src/components/Spinner";
import PasswordInputField from "comp/FormComponents/passwordField";
import { Icon } from "@iconify/react";

interface Props {
  error?: any;
  email?: any;
  isRequesting?: any;
  submitData?: any;
}

const PasswordSet = ({ email, error, isRequesting, submitData }: Props) => {
  const [password, sePassword] = useState<any>({
    password: "",
    email_offer_subscription: false,
    agree_terms_condition: true,
  });
  const [errors, setErrors] = useState<any>({});
  const handleChange = (event: any) => {
    delete errors[event.target.name];
    const {
      target: { name, value },
    } = event;
    if (name === "email_offer_subscription")
      sePassword({ ...password, [name]: !password[name] });
    else sePassword({ ...password, password: event.target.value });
  };
  const handleSubmit = () => {
    if (!password.password) setErrors({ password: "Enter Password" });
    else {
      submitData(password);
    }
  };
  return (
    <div>
      <div className="flex justify-center">
        <Icon icon="material-symbols:lock" color="red" width="50" height="50" />
      </div>
      <div className="text-sm my-3 text-center">
        Seems that you haven't set a password yet{" "}
        {email ? `for ${email}` : null}
      </div>
      <AlertMessage
        showIcon={false}
        message={error}
        type={error ? "error" : ""}
      />
      <div className="register-input changePassword">
        <PasswordInputField
          showProgress
          maxLength={30}
          errors={errors}
          data={password}
          label="Password"
          name={"password"}
          placeholder="Password"
          handleChange={handleChange}
        />
      </div>
      <div className="field mb-2">
        <label className="custom-control custom-checkbox">
          <input
            className="custom-control-input"
            type="checkbox"
            name="email_offer_subscription"
            onChange={handleChange}
            checked={password.email_offer_subscription || false}
          />
          <span className="custom-control-indicator" />{" "}
          <span className="text-sm text-gray-400 ml-1">
            Subscribe for newsletter
          </span>{" "}
        </label>
      </div>
      <span className="text-sm">
        By signing up, you agree to the{" "}
        <a
          target={"_blank"}
          href="/terms-of-service"
          className="text-blue-500/100"
        >
          terms and conditions
        </a>{" "}
        as User.
      </span>
      <div className="flex justify-end">
        <Button disabled={isRequesting} onClick={() => handleSubmit()}>
          <Spinners isLoading={isRequesting} alternateName="Submit" />
        </Button>
      </div>
    </div>
  );
};

export default PasswordSet;
