import GoogleLogin from "react-google-login";
import { GOOGLE_CLIENT_ID } from "constants/constants";

interface Props {
  handleLogin: any;
  seterrors: any;
  text?: any;
}

const LoginByGoogle = (props: Props) => {
  const { seterrors, handleLogin, text = "Sign in with Google" } = props;
  const handleGoogleLogin = (evt: any, type: string) => {
    if (type === "error") {
      if (evt.error !== "popup_closed_by_user") {
        seterrors({ errResp: evt.error });
      }
    } else {
      return handleLogin(evt.accessToken);
    }
  };

  return (
    <GoogleLogin
      autoLoad={false}
      className={`google button fluid ${"loadingGoogle" ? "loading" : ""}`}
      clientId={GOOGLE_CLIENT_ID}
      scope="https://www.googleapis.com/auth/plus.login https://www.googleapis.com/auth/plus.me email profile"
      onSuccess={(evt) => handleGoogleLogin(evt, "success")}
      buttonText={text}
      onFailure={(evt) => handleGoogleLogin(evt, "error")}
      cookiePolicy={"single_host_origin"}
    ></GoogleLogin>
  );
};

export default LoginByGoogle;
