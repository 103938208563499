import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "types";
import { initialState } from "./slice";

// First select the relevant part from the state
const selectDomain = (state: RootState) => state?.global || initialState;

export const selectRequesting = createSelector(
  [selectDomain],
  (global: { requesting: any }) => global.requesting
);

export const selectSuccess = createSelector(
  [selectDomain],
  (global: { success: any }) => global.success
);

export const selectWishListSuccess = createSelector(
  [selectDomain],
  (global: { wishListSuccess: any }) => global.wishListSuccess
);

export const selectError = createSelector(
  [selectDomain],
  (global: { error: any }) => global.error
);

export const selectCryptoList = createSelector(
  [selectDomain],
  (global: { cryptoList: any }) => global.cryptoList
);

export const selectDefaultCurrency = createSelector(
  [selectDomain],
  (global: { defaultCurrency: any }) => global.defaultCurrency
);
export const selectLoading = createSelector(
  [selectDomain],
  (global: { requesting: any }) => global.requesting
);
export const selectPageTemplate = createSelector(
  [selectDomain],
  (global: { pageTemplate: any }) => global.pageTemplate
);
export const selectExchangeRate = createSelector(
  [selectDomain],
  (global: { exchangeRate: any }) => global.exchangeRate
);

export const selectUserData = createSelector(
  [selectDomain],
  (global: { userData: any }) => global.userData
);
export const selectShowDialog = createSelector(
  [selectDomain],
  (global: { showModal: any }) => global.showModal
);

export const selectHotelCryptoData = createSelector(
  [selectDomain],
  (global: { hotelCryptoList: any }) => global.hotelCryptoList
);

export const selectUserWishList = createSelector(
  [selectDomain],
  (global: { userWishList: any }) => global.userWishList
);

export const SelectWishListReqResponse = createSelector(
  [selectDomain],
  (global: { wishListResponse: any }) => global.wishListResponse
);

export const selectModaProps = createSelector(
  [selectDomain],
  (global: { modalProps: any }) => global.modalProps
);

export const selectFiatList = createSelector(
  [selectDomain],
  (global: { fiatList: any }) => global.fiatList
);

export const selectEventList = createSelector(
  [selectDomain],
  (global: { eventList: any }) => global.eventList
);

export const selectHotelRecentSearch = createSelector(
  [selectDomain],
  (global: { recentHotelSearch: any }) => global.recentHotelSearch
);

export const selectFlightRecentSearch = createSelector(
  [selectDomain],
  (global: { recentFlightSearch: any }) => global.recentFlightSearch
);
