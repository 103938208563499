import { createContext, useContext, useLayoutEffect, useState } from "react";
import { DARK_MODE_LOCAL_KEY } from "src/pages/_app";
import CookieUtils from "src/utils/cookie.helper";

const initialState = {
    darkMode: false,
    setDarkMode: (status: boolean) => { },
};

const ThemeContext = createContext(initialState);

const ThemeContextProvider = function ({ initialMode, children }: any) {
    const [darkMode, _setDarkMode] = useState(initialMode);


    useLayoutEffect(() => {
        if (initialMode) {
            document.documentElement.classList.add("dark");
            return
        }

        document.documentElement.classList.remove("dark");

    }, [initialMode])

    const setDarkMode = (status: boolean) => {
        _setDarkMode(status)

        if (status) {
            document.documentElement.classList.add("dark");
            CookieUtils.updateCookie(DARK_MODE_LOCAL_KEY, "true", 60)
            return;
        }
        
        document.documentElement.classList.remove("dark");
        CookieUtils.updateCookie(DARK_MODE_LOCAL_KEY, "false", 60)
    }

    return (
        <ThemeContext.Provider value={{ darkMode, setDarkMode }}>
            {children}
        </ThemeContext.Provider>
);
};

const useTheme = () => {
    const context = useContext(ThemeContext);

    if (context === undefined) {
        throw new Error("useTheme must be used within a ThemeContextProvider");
    }

    return context;
}

export { ThemeContextProvider, useTheme };

