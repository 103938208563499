import React, { useEffect, useRef } from "react";
import AlertMessage from "comp/Alert";
import InputForm from "comp/FormComponents/inputForm";
import Select from "comp/FormComponents/selectForm";
import { prefixData } from "../messageConfig";
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_SITE_KEY, GOOGLE_CLIENT_ID } from "constants/constants";
import LoginByGoogle from "comp/Sociallogin/googleLogin";
import PasswordInputField from "comp/FormComponents/passwordField";
import Spinners from "comp/Spinner";
import { Button } from "flowbite-react";
import Link from "next/link";

interface Props {
  data: any;
  errors: any;
  seterrors: any;
  countryList: any;
  inPopup: boolean;
  handleChange: any;
  handleSubmit: any;
  isLoading: boolean;
  successMessage: any;
  handleRecaptcha: any;
  handleGoogleLogin: any;
  isFocusable: any;
  recaptchaRef: any;
}

const SignupForm = (props: Props) => {
  const {
    data,
    errors,
    inPopup,
    seterrors,
    isLoading,
    countryList,
    isFocusable,
    recaptchaRef,
    handleChange,
    handleSubmit,
    successMessage,
    handleRecaptcha,
    handleGoogleLogin,
  } = props;
  const handleaction = (e) => {
    e.preventDefault();
  };
  const errorRef: any = useRef(null);
  useEffect(() => {
    if (errors.errResp) {
      errorRef.current.focus();
    }
  }, [errors]);

  return (
    <>
      <div className={` ${inPopup ? " " : " my-8 container mx-auto"} `}>
        <div className=" bg-white dark:bg-dark-bg-secondary p-4 shadow-m1  rounded-xl    lg:w-[600px] mx-auto">
          <div>
            <p className="m-0 text-lg font-medium text-black dark:text-dark-text-primary">
              Create Account
            </p>
            <p className="m-0 text-sm text-gray-600 dark:text-dark-text-secondary">
              Travel exciting place by booking flights and hotel with XcelTrip.
            </p>

            <div className="register-external my-4  border-none !rounded-xl">
              <LoginByGoogle
                seterrors={seterrors}
                text="Sign up with Google"
                handleLogin={handleGoogleLogin}
              />
            </div>
            <AlertMessage message={successMessage} type="success" />
            <p ref={errorRef}>
              <AlertMessage message={errors.errResp} type="error" />
            </p>

            <div action={handleaction} className="grid grid-cols-6 gap-2 ">
              <div className="col-span-6 sm:col-span-2 signup_select">
                <Select
                  name="gender"
                  handleChange={handleChange}
                  value={data.gender}
                  errors={errors}
                  label={"Title *"}
                  placeholder={"Prefix"}
                  array={prefixData}
                  isFocusable={isFocusable}
                />
              </div>
              <div className="col-span-6 sm:col-span-2 register-input register-input-detailPage">
                <InputForm
                  label={"First Name *"}
                  errors={errors}
                  placeholder="First Name"
                  name="first_name"
                  value={data.first_name || ""}
                  handleChange={handleChange}
                  isFocusable={isFocusable}
                  className={`dark:bg-transparent dark:border-gray-600`}
                />
              </div>

              <div className="col-span-6 sm:col-span-2 register-input register-input-detailPage ">
                <InputForm
                  label={"Last Name *"}
                  errors={errors}
                  placeholder="Last Name"
                  name="last_name"
                  value={data.last_name || ""}
                  handleChange={handleChange}
                  isFocusable={isFocusable}
                  className={`dark:bg-transparent dark:border-gray-600`}
                />
              </div>

              <div className="col-span-6 lg:col-span-3 register-input register-input-detailPage">
                <InputForm
                  label={"Email *"}
                  errors={errors}
                  placeholder="Email"
                  name="email"
                  value={data.email || ""}
                  handleChange={handleChange}
                  isFocusable={isFocusable}
                  className={`dark:bg-transparent dark:border-gray-600`}
                />
              </div>

              <div className="col-span-6 lg:col-span-3 register-input register-input-detailPage">
                <PasswordInputField
                  data={data}
                  errors={errors}
                  maxLength={30}
                  name="password"
                  label={"Password *"}
                  placeholder="Password"
                  handleChange={handleChange}
                  isFocusable={isFocusable}
                  // className={`dark:bg-transparent `}
                />
              </div>

              <div className="col-span-6 lg:col-span-3 register-input register-input-detailPage">
                <Select
                  name="address_country"
                  handleChange={handleChange}
                  value={data.address_country}
                  errors={errors}
                  label={"Country *"}
                  array={countryList}
                  isFocusable={isFocusable}
                />
              </div>

              <div className="col-span-6 lg:col-span-3 register-input register-input-detailPage">
                <InputForm
                  label={"City (Optional)"}
                  errors={errors}
                  placeholder="City"
                  name="address_city"
                  handleChange={handleChange}
                  value={data.address_city || ""}
                  isFocusable={isFocusable}
                  className={`dark:bg-transparent dark:border-gray-600`}
                />
              </div>

              <div className="col-span-6">
                <label for="MarketingAccept" className="flex gap-2">
                  <input
                    name="email_offer_subscription"
                    type="checkbox"
                    // defaultChecked={true}
                    onChange={handleChange}
                    checked={data.email_offer_subscription}
                    className="w-5 h-5 bg-white border-gray-200 rounded-md shadow-sm"
                  />

                  <span className="text-sm text-gray-700 dark:text-dark-text-primary">
                    Please send me emails with travel deals, special offers and
                    other information.
                  </span>
                </label>
              </div>

              <div className="col-span-6">
                <label for="MarketingAccept" className="flex gap-2">
                  <input
                    name="agree_terms_condition"
                    type="checkbox"
                    checked={data.agree_terms_condition}
                    className={`
                        ${(errors.agree_terms_condition && "input-error") || ""}
                        h-5 w-5 rounded-md border-gray-200 bg-white shadow-sm`}
                    onChange={handleChange}
                    ref={(input) =>
                      isFocusable &&
                      input &&
                      isFocusable(`${"agree_terms_condition"}`) &&
                      input.focus()
                    }
                  />

                  <span className="text-sm text-gray-700 dark:text-dark-text-primary">
                    I have read and agreed to the Terms and Use & the Privacy
                    Policy.
                  </span>
                </label>
              </div>

              <div className="col-span-6">
                <div className="sm:block">
                  <ReCAPTCHA
                    sitekey={RECAPTCHA_SITE_KEY}
                    onChange={handleRecaptcha}
                    onExpired={handleRecaptcha}
                    className="w-full"
                    ref={recaptchaRef}
                  />
                  <AlertMessage message={errors.reCaptcha} type="error" />
                </div>
              </div>

              <div className="col-span-6 sm:flex sm:items-center sm:gap-4">
                <Button
                  disabled={isLoading}
                  type="submit"
                  onClick={handleSubmit}
                  className={`  button-flight w-full  ${
                    isLoading && "loading"
                  }`}
                >
                  <Spinners isLoading={isLoading} alternateName={"Register"} />
                </Button>

                {/* <p className="m-0 mt-4 text-sm text-gray-500 sm:mt-0">
                        Already have an account?
                        <Link
                          className="underline"
                          legacyBehavior
                          href={"login"}
                        >
                          <a href="#" className="ml-2 text-gray-700 underline">
                            Log in
                          </a>
                        </Link>
                      </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SignupForm;
