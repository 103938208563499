import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import AlertMessage from "comp/Alert";
import { Progress } from "flowbite-react";
import { passwordHelper } from "utils/helperFunctions";
import InputForm from "./inputForm";

interface Props {
  data?: any;
  label: any;
  errors?: any;
  name?: string;
  errorName?: any;
  placeholder: any;
  maxLength: number;
  handleChange: any;
  isFocusable?: any;
  showProgress?: any;
  showErrorLable?: any;
  labelClass?: any;
}

const PasswordInputField = ({
  data,
  errors,
  name = "",
  label,
  errorName,
  placeholder,
  handleChange,
  isFocusable,
  maxLength = 30,
  showProgress = false,
  showErrorLable = true,
  labelClass = true,
}: Props) => {
  const [passwordType, setpasswordType] = useState("password");
  const [passwordStrength, setPasswordStrength] = useState(0);
  useEffect(() => {
    if (showProgress) {
      const passwordChecked = passwordHelper(data[name]) || {};
      const passwordStrength = 5 - Object.keys(passwordChecked).length;
      setPasswordStrength(passwordStrength);
      setPasswordStrength(passwordStrength);
    }
  }, [data]);
  return (
    <div className="form-control">
      <label
        className={`${labelClass} pb-1.5 text-sm flex undefined dark:text-dark-text-primary`}
      >
        {label}
      </label>
      <div className=" group">
        <input
          name={name}
          maxLength={maxLength}
          disabled={false}
          type={passwordType}
          autoComplete={"new-password"}
          value={data[name]}
          onChange={handleChange}
          placeholder={placeholder}
          className={`dark:bg-transparent

          ${
            errors[errorName || name] &&
            "input-error !border-red-500 !border-[1px] "
          }`}
          ref={(input) =>
            isFocusable && input && isFocusable(`${name}`) && input.focus()
          }
        />
        <div
          onClick={() =>
            setpasswordType(passwordType === "text" ? "password" : "text")
          }
          className="cursor-pointer"
          style={{
            float: "right",
            marginRight: "12px",
            marginTop: "-32px",
          }}
        >
          <Icon
            color="gray"
            icon={`mdi:eye${(passwordType !== "text" && "-off") || ""}-outline`}
            className=""
          />
        </div>{" "}
      </div>

      <span>
        {showErrorLable && (
          <AlertMessage message={errors[errorName || name]} type={"error"} />
        )}
      </span>
      {showProgress && data[name] && (
        <React.Fragment>
          <div className="changePassword-progressbar">
            <Progress
              progress={passwordStrength * 20}
              size="md"
              color={
                passwordStrength <= 2
                  ? "red"
                  : passwordStrength <= 4
                  ? "yellow"
                  : "green"
              }
            />
            <span
              className={` ${
                passwordStrength <= 2
                  ? " bg-red-200 text-red-500"
                  : passwordStrength <= 4
                  ? "bg-[#fbd682] text-yellow-600"
                  : "bg-green-200 text-green-500"
              } rounded-full text-sm py-1 px-4`}
            >
              {passwordStrength <= 2
                ? "Weak"
                : passwordStrength <= 4
                ? "Good"
                : "Strong"}
            </span>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default PasswordInputField;
