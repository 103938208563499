import { call, put, cancelled } from "redux-saga/effects";
import { BASE_URL } from "constants/constants";
import { get, requestJSON, request } from "./request";
import ObjectToFormData from "./ObjectToFormData";
import Router from "next/router";
import { excludeRoute } from "constants/apiConfig";

class API {
  /**
   * Generic api data loader
   */
  static dataLoader(
    apiUri: string,
    onSuccess: (arg0: any, arg1: any, arg2: string, arg3: any) => any,
    onError: (arg0: any, arg1: any) => any,
    data: undefined,
    token: undefined,
    metaData = "",
    ...actionArguments: string[]
  ) {
    return function* () {
      let controller = new AbortController();
      let signal = controller.signal;
      const baseUrl = BASE_URL;
      let requestURL = "";
      if (/^https?:\/\//i.test(apiUri)) {
        requestURL = apiUri;
      } else {
        requestURL = `${baseUrl}${apiUri}`;
      }

      try {
        let options;
        if (data !== undefined) {
          options = {
            method: metaData.toLowerCase() === "put" ? "PUT" : "POST",
            body: JSON.stringify(data),
            headers: {
              "Content-Type": "application/json",
              "X-Requested-With": "XMLHttpRequest",
              "Access-Control-Allow-Origin": "*",
              "User-Agent": navigator.userAgent,
              Authorization: token !== undefined ? token : "",
            },
          };
        } else {
          options = {
            method: "GET",
            headers: {
              // 'Content-Type': 'application/json',
              "Content-Type": "application/json",
              "X-Requested-With": "XMLHttpRequest",
              "Access-Control-Allow-Origin": "*",
              "User-Agent": navigator.userAgent,
              Authorization: token !== undefined ? token : "",
            },
          };
        }
        const response = yield call(requestJSON, requestURL, {
          ...options,
          signal,
        });
        yield put(onSuccess(response, data, metaData, ...actionArguments));
      } catch (err: any) {
        let error;
        if (err?.response?.status === 401) {
          var result = excludeRoute.findIndex((account) => {
            return apiUri.startsWith(account);
          }, apiUri);
          // console.log(result, "FROM THE RESULT,", excludeRoute, result === -1);
          if (result === -1) {
            localStorage.removeItem("tokenBased");
            document.cookie = `tokenBased=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;
            Router.push("/");
          }
        }
        try {
          error = yield call(() => err.response.json());
        } catch (a: any) {
          error = {
            errors: [
              {
                code: a.response,
                msg: a.response,
              },
            ],
          };
        }
        if (error) yield put(onError(error, ...actionArguments));
      } finally {
        if (yield cancelled()) {
          controller.abort();
        }
      }
    };
  }
  /*
   * Shorthand GET function
   */
  static get(
    apiUri: any,
    onSuccess: any,
    onError: any,
    token: any,
    ...actionArguments: any[]
  ) {
    return this.dataLoader(
      apiUri,
      onSuccess,
      onError,
      undefined,
      token,
      "GET",
      ...actionArguments
    );
  }
  /*
   * Shorthand POST function
   */
  static post(
    apiUri: string,
    onSuccess: any,
    onError: any,
    data: any,
    token: any,
    metaData: string | undefined,
    ...actionArguments: any
  ) {
    return this.dataLoader(
      apiUri,
      onSuccess,
      onError,
      data,
      token,
      metaData,
      ...actionArguments
    );
  }
  /*
   * Shorthand PUT function
   */
  static put(
    apiUri: any,
    onSuccess: any,
    onError: any,
    data: any,
    token: any,
    metaData = "put",
    ...actionArguments: any[]
  ) {
    return this.dataLoader(
      apiUri,
      onSuccess,
      onError,
      data,
      token,
      metaData,
      ...actionArguments
    );
  }

  static patch(
    apiUri: any,
    onSuccess: (arg0: any, arg1: any[]) => any,
    onError: (arg0: any, arg1: any) => any,
    data = {},
    token: undefined,
    ...actionArguments: any[]
  ) {
    return function* () {
      const requestURL = `${apiUri}`;
      try {
        const options = {
          method: "PATCH",
          body: JSON.stringify(data),
          headers: {
            // 'Content-Type': 'application/json',
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            "Access-Control-Allow-Origin": "*",
            Authorization: token !== undefined ? token : "", //? `${usertoken}` : undefined
          },
        };
        const response = yield call(get, requestURL, options);
        yield put(onSuccess(response, actionArguments));
      } catch (e) {
        let error = null;
        try {
          error = yield call(() => e.response.json());
        } catch (_) {
          let error;
          error = {
            errors: [
              {
                code: e.response,
                msg: e.response,
              },
            ],
          };
        }
        yield put(onError(error, ...actionArguments));
      }
    };
  }

  static delete(
    apiUri: any,
    onSuccess: (arg0: any, arg1: any[]) => any,
    onError: (arg0: any, arg1: any) => any,
    token: undefined,
    ...actionArguments: any[]
  ) {
    return function* () {
      const requestURL = `${apiUri}`;
      try {
        const options = {
          method: "DELETE",

          headers: {
            // 'Content-Type': 'application/json',
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            "Access-Control-Allow-Origin": "*",
            Authorization: token !== undefined ? token : "", //? `${usertoken}` : undefined
          },
        };
        const response = yield call(get, requestURL, options);
        yield put(onSuccess(response, actionArguments));
      } catch (e) {
        let error = null;
        try {
          error = yield call(() => e.response.json());
        } catch (_) {
          let error;
          error = {
            errors: [
              {
                code: e.response,
                msg: e.response,
              },
            ],
          };
        }
        yield put(onError(error, ...actionArguments));
      }
    };
  }

  static multipartPost(
    apiUri: any,
    onSuccess: (arg0: any, arg1: any) => any,
    onError: (arg0: any, arg1: any) => any,
    data: any,
    document: string | any[] | Blob | any,
    token = "",
    metaData = "",
    ...actionArguments: any[]
  ) {
    // eslint-disable-next-line func-names
    return function* () {
      const requestURL = `${BASE_URL}${apiUri}`;
      let multipartData: any = new FormData();
      multipartData = ObjectToFormData(data, multipartData);

      if (Object.prototype.toString.call(document) === "[object Array]") {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < document.length; i++) {
          console.log(
            i,
            document[i],
            'Object.prototype.toString.call(document) === "[object Array]"'
          );
          multipartData.append("file", document[i]);
        }
      } else {
        console.log("testin tetns");
        multipartData.append("file", document);
      }
      try {
        const options = {
          method: metaData.toLowerCase() === "put" ? "PUT" : "POST",
          body: multipartData,
          headers: {
            processData: false,
            // 'Content-Type': 'multipart/form-data',
            contentType: false,
            "X-Requested-With": "XMLHttpRequest",
            Authorization: token,
          },
        };
        const response = yield call(request, requestURL, options);
        // const mockData = {"status":200,"data":"https://bfi-swift.bitsbeat.com/product/1608274884468-image_2020_06_15T10_02_16_746Z.png","message":"File has been uploaded successfully"}
        yield put(onSuccess(response, ...actionArguments));
      } catch (e) {
        let error;
        try {
          error = yield call(() => e.response.json());
        } catch (a) {
          // if (Object.keys(a).length === 0) {
          //   error = {
          //     status: 500,
          //     message: "There might be internet problem Can you pls check it."
          //   };
          // } else {
          error = {
            status: e.response,
            message: e.response,
          };
          // error = {
          //   errors: [
          //     {
          //       code: e.response.status || 123,
          //       msg: e.response.statusText,
          //     },
          //   ],
          // };
          // }
        }
        // check if token expiry
        // const tokenIsNotValid = invalidTokenHelper(error);
        // if (tokenIsNotValid) {
        //   localStorage.clear();
        //   sessionStorage.removeItem('token');
        //   yield put(logoutSuccess());
        // } else {
        yield put(onError(error, ...actionArguments));
        // }
      }
    };
  }

  static async getData(apiUri = "", useragent = "", token = "") {
    try {
      const baseUrl = BASE_URL;
      let requestURL = "";
      const BGred = "\x1b[41m";
      const BGyellow = "\x1b[43m";
      if (/^https?:\/\//i.test(apiUri)) {
        requestURL = apiUri;
      } else {
        requestURL = `${baseUrl}${apiUri}`;
      }
      // console.log(BGyellow, requestURL, "<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<FROM BASE URL>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",)
      let options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "User-Agent": useragent,
          Authorization: token,
        },
      };
      const response = await fetch(requestURL, options)
        .then((res) => res.json())
        .then((data) => data)
        .catch((e) =>
          console.log(
            e,
            "<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<err frm catc>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>h>>>>>>>>>>>>>>>>>>>>>>>>>>>>",
            // BGred
            baseUrl,
            requestURL
          )
        );
      return response;
    } catch (err) {
      return err;
    }
  }

  static async postData(
    apiUri = "",
    data = {},
    useragent = "",
    method = "POST"
  ) {
    try {
      const baseUrl = BASE_URL;
      let requestURL = "";
      const BGred = "\x1b[41m";
      const BGyellow = "\x1b[43m";
      if (/^https?:\/\//i.test(apiUri)) {
        requestURL = apiUri;
      } else {
        requestURL = `${baseUrl}${apiUri}`;
      }
      let options = {
        method: method,
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          // 'User-Agent': useragent,
          // Authorization: token
        },
      };
      const response = await fetch(requestURL, options)
        .then((res) => res.json())
        .then((data) => data)
        .catch((e) => {
          return e;
        });
      return response;
    } catch (err) {
      return err;
    }
  }
}

export default API;
