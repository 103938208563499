import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'types'
import { initialState } from './slice'

// First select the relevant part from the state
const selectDomain = (state: RootState) => state?.login || initialState

export const selectRequesting = createSelector(
    [selectDomain],
    loginState => loginState.requesting,
)

export const selectSuccess = createSelector(
    [selectDomain],
    loginState => loginState.success,
)

export const selectError = createSelector(
    [selectDomain],
    loginState => loginState.error,
)

export const selectUserInfo = createSelector(
    [selectDomain],
    loginState => loginState.userInfo,
)

export const selectLoginData = createSelector(
    [selectDomain],
    loginState => loginState.loginData,
)

export const selectLoading = createSelector(
    [selectDomain],
    loginState => loginState.requesting,
)

export const selectResponseMessage = createSelector(
    [selectDomain],
    loginState => loginState.responseMessage,
)
