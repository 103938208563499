import { take, takeLatest, fork, put, select, delay } from "redux-saga/effects";
import API from "utils/apiHelper";
import { initializeActions } from "./slice";
import Router from "next/router";
import { currencyApi, mapCryptoCoinId } from "constants/constants";
import { tokenId } from "utils/authorizationHelper";
import {
  search,
  getCryptoList,
  wishList,
  eventList,
  onBoarding,
  contentTemplate,
} from "constants/apiConfig";

export function* getCryptoListReq(action: any) {
  const mockedData = false;
  try {
    yield delay(1000);
    if (mockedData) {
      yield put(
        initializeActions.getCryptoListSucc({
          status: 200,
          data: {
            crypto: [
              {
                symbol: "XLAB",
                name: "XcelToken Plus",
                coinId: "xceltoken-plus",
                forHotel: true,
                forFlight: true,
                imgUrl: "/payment-static/image/xlab.png",
                exRate: 0.00000497,
                to: "USD",
              },
              {
                symbol: "BTC",
                name: "Bitcoin",
                coinId: "bitcoin",
                forHotel: true,
                forFlight: true,
                imgUrl: "/payment-static/image/full__bitcoin.png",
                exRate: 17457.71,
                to: "USD",
              },
              {
                symbol: "ETH",
                name: "Ethereum",
                coinId: "ethereum",
                forHotel: true,
                forFlight: true,
                imgUrl: "/payment-static/image/full_ethereum.png",
                exRate: 1283.71,
                to: "USD",
              },
              {
                symbol: "XVG",
                name: "Verge",
                coinId: "verge",
                forHotel: true,
                forFlight: true,
                imgUrl: "/payment-static/image/verge.png",
                exRate: 0.00262913,
                to: "USD",
              },
              {
                symbol: "LTC",
                name: "Litecoin",
                coinId: "litecoin",
                forHotel: true,
                forFlight: true,
                imgUrl: "/payment-static/image/full_litecoin.png",
                exRate: 76.7,
                to: "USD",
              },
              {
                symbol: "KAU",
                name: "Kauri",
                coinId: "kau",
                forHotel: true,
                forFlight: true,
                imgUrl: "/payment-static/image/kinesis.png",
                exRate: 0,
                to: "USD",
              },
              {
                symbol: "USDT",
                name: "Tether",
                coinId: "tether",
                forHotel: true,
                forFlight: true,
                imgUrl: "/payment-static/image/tether.png",
                exRate: 1.001,
                to: "USD",
              },
              {
                symbol: "XDC",
                name: "XDC Network",
                coinId: "ormeus-cash",
                forHotel: true,
                forFlight: true,
                imgUrl: "/payment-static/image/xdc-logo.png",
                exRate: 0.00008001,
                to: "USD",
              },
              {
                symbol: "XLD",
                name: "XcelPay",
                coinId: "xcel-swap",
                forHotel: true,
                forFlight: true,
                imgUrl: "/payment-static/image/xld_bep20.jpg",
                exRate: 0.00281434,
                to: "USD",
              },
              {
                symbol: "BNB",
                name: "BNB",
                coinId: "binancecoin",
                forHotel: true,
                forFlight: true,
                imgUrl: "/payment-static/image/bsc.png",
                exRate: 269.54,
                to: "USD",
              },
              {
                symbol: "BCH",
                name: "Bitcoin Cash",
                coinId: "bitcoin-cash",
                forHotel: true,
                forFlight: true,
                imgUrl: "/payment-static/image/bch.png",
                exRate: 110.52,
                to: "USD",
              },
              {
                symbol: "MATIC",
                name: "Polygon",
                coinId: "matic-network",
                forHotel: true,
                forFlight: true,
                imgUrl: "/payment-static/image/matic.png",
                exRate: 0.904668,
                to: "USD",
              },
              {
                symbol: "SHIB",
                name: "Shiba Inu",
                coinId: "shiba-inu",
                forHotel: true,
                forFlight: true,
                imgUrl: "/payment-static/image/shib-logo.png",
                exRate: 0.0000089,
                to: "USD",
              },
              {
                symbol: "APE",
                name: "ApeCoin",
                coinId: "apecoin",
                forHotel: true,
                forFlight: true,
                imgUrl: "/payment-static/image/ape-coin.png",
                exRate: 4.07,
                to: "USD",
              },
              {
                symbol: "NEWTEST",
                name: "Volt-Inu",
                coinId: "newcoin",
                forHotel: true,
                forFlight: true,
                imgUrl: "/payment-static/image/Volt-Inu-Logo.png",
                exRate: 0,
                to: "USD",
              },
            ],
            fiat: [
              {
                label: "Australian Dollar",
                value: "AUD",
                symbol: "$",
                image:
                  "https://raw.githubusercontent.com/cychiang/currency-icons/master/icons/currency/aud.png",
                exRate: 0.6696847124373845,
                to: "USD",
              },
              {
                label: "Baht",
                value: "THB",
                symbol: "฿",
                image:
                  "https://raw.githubusercontent.com/cychiang/currency-icons/master/icons/currency/thb.png",
                exRate: 0.028576342885938015,
                to: "USD",
              },
              {
                label: "Bangladeshi Taka",
                value: "BDT",
                symbol: "৳",
                image:
                  "https://raw.githubusercontent.com/cychiang/currency-icons/master/icons/currency/bdt.png",
                exRate: 0.0096489964305564,
                to: "USD",
              },
              {
                label: "Brazilian Real",
                value: "BRL",
                symbol: "$",
                image:
                  "https://raw.githubusercontent.com/cychiang/currency-icons/master/icons/currency/brl.png",
                exRate: 0.19105616420742358,
                to: "USD",
              },
              {
                label: "Canadian Dollars",
                value: "CAD",
                symbol: "$",
                image:
                  "https://raw.githubusercontent.com/cychiang/currency-icons/master/icons/currency/cad.png",
                exRate: 0.7333222357234994,
                to: "USD",
              },
              {
                label: "Euro",
                value: "EUR",
                symbol: "€",
                image:
                  "https://raw.githubusercontent.com/cychiang/currency-icons/master/icons/currency/eur.png",
                exRate: 1.046443244057772,
                to: "USD",
              },
              {
                label: "Indian Rupee",
                value: "INR",
                symbol: "₹",
                image:
                  "https://raw.githubusercontent.com/cychiang/currency-icons/master/icons/currency/inr.png",
                exRate: 0.012130375038929407,
                to: "USD",
              },
              {
                label: "Israeli New Shekel",
                value: "ILS",
                symbol: "₪",
                image:
                  "https://raw.githubusercontent.com/cychiang/currency-icons/master/icons/currency/ils.png",
                exRate: 0.2923075753846622,
                to: "USD",
              },
              {
                label: "Malaysian Ringgit",
                value: "MYR",
                symbol: "RM",
                image:
                  "https://raw.githubusercontent.com/cychiang/currency-icons/master/icons/currency/myr.png",
                exRate: 0.22707094377496362,
                to: "USD",
              },
              {
                label: "Mexican Peso",
                value: "MXN",
                symbol: "$",
                image:
                  "https://raw.githubusercontent.com/cychiang/currency-icons/master/icons/currency/mxn.png",
                exRate: 0.05056895894240484,
                to: "USD",
              },
              {
                label: "Naira",
                value: "NGN",
                symbol: "₦",
                image:
                  "https://raw.githubusercontent.com/cychiang/currency-icons/master/icons/currency/ngn.png",
                exRate: 0.002249306349910589,
                to: "USD",
              },
              {
                label: "Nepali Rupees",
                value: "NPR",
                symbol: "Rs.",
                image:
                  "https://raw.githubusercontent.com/cychiang/currency-icons/master/icons/currency/npr.png",
                exRate: 0.00753914088232737,
                to: "USD",
              },
              {
                label: "Pakistan Rupee",
                value: "PKR",
                symbol: "Rs",
                image:
                  "https://raw.githubusercontent.com/cychiang/currency-icons/master/icons/currency/pkr.png",
                exRate: 0.004436666357046511,
                to: "USD",
              },
              {
                label: "Philippine Peso",
                value: "PHP",
                symbol: "₱",
                image:
                  "https://raw.githubusercontent.com/cychiang/currency-icons/master/icons/currency/php.png",
                exRate: 0.01801326349014654,
                to: "USD",
              },
              {
                label: "Pound Sterling",
                value: "GBP",
                symbol: "£",
                image:
                  "https://raw.githubusercontent.com/cychiang/currency-icons/master/icons/currency/gbp.png",
                exRate: 1.214562113921068,
                to: "USD",
              },
              {
                label: "Russian Ruble",
                value: "RUB",
                symbol: "₽",
                image:
                  "https://raw.githubusercontent.com/cychiang/currency-icons/master/icons/currency/rub.png",
                exRate: 0.01585204713733033,
                to: "USD",
              },
              {
                label: "Singapore Dollar",
                value: "SGD",
                symbol: "S$",
                image:
                  "https://raw.githubusercontent.com/cychiang/currency-icons/master/icons/currency/sgd.png",
                exRate: 0.7368827501643249,
                to: "USD",
              },
              {
                label: "South Korean Won",
                value: "KRW",
                symbol: "₩",
                image:
                  "https://raw.githubusercontent.com/cychiang/currency-icons/master/icons/currency/krw.png",
                exRate: 0.000761604393255842,
                to: "USD",
              },
              {
                label: "Swiss Franc",
                value: "CHF",
                symbol: "CHf",
                image:
                  "https://raw.githubusercontent.com/cychiang/currency-icons/master/icons/currency/chf.png",
                exRate: 1.0617413194684073,
                to: "USD",
              },
              {
                label: "Turkish Lira",
                value: "TRY",
                symbol: "₺",
                image:
                  "https://raw.githubusercontent.com/cychiang/currency-icons/master/icons/currency/try.png",
                exRate: 0.05369825810368468,
                to: "USD",
              },
              {
                label: "UAE Dirham",
                value: "AED",
                symbol: "د.إ",
                image:
                  "https://raw.githubusercontent.com/cychiang/currency-icons/master/icons/currency/aed.png",
                exRate: 0.2723669807330321,
                to: "USD",
              },
              {
                label: "US Dollar",
                value: "USD",
                symbol: "$",
                image:
                  "https://raw.githubusercontent.com/cychiang/currency-icons/master/icons/currency/usd.png",
                exRate: 1,
                to: "USD",
              },
              {
                label: "Yen",
                value: "JPY",
                symbol: "¥",
                image:
                  "https://raw.githubusercontent.com/cychiang/currency-icons/master/icons/currency/jpy.png",
                exRate: 0.007288165834167422,
                to: "USD",
              },
              {
                label: "Yuan",
                value: "CNY",
                symbol: "¥",
                image:
                  "https://raw.githubusercontent.com/cychiang/currency-icons/master/icons/currency/cny.png",
                exRate: 0.14335180899231564,
                to: "USD",
              },
            ],
          },
          defaultForHotel: "BTC",
          defaultForFlight: "BTC",
        })
      );
    } else
      yield fork(
        API.get(
          // "https://www.xceltrip.com/api/pg/v1/supported-coins/available",
          getCryptoList,
          initializeActions.getCryptoListSucc,
          initializeActions.getCryptoListFail,
          "",
          "GET"
        )
      );
  } catch (err) {
    yield put(initializeActions.getCryptoListFail(err));
  }
}

export function* logoutRequest(action: any) {
  const Storedtoken: any = localStorage.getItem("tokenBased");
  try {
    const logoutResp = yield fork(
      API.delete(
        onBoarding.logoutAPi,
        initializeActions.logoutSuccess,
        initializeActions.logoutFailure,
        Storedtoken
      )
    );
    if (logoutResp) {
      yield take(initializeActions.logoutSuccess);
      localStorage.removeItem("tokenBased");
      localStorage.removeItem("guest_token");
      document.cookie = `tokenBased=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;
      document.cookie = `guest_token=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;
      yield put(initializeActions.clearRedux());
      yield put(initializeActions.clearRecentSearchRedux());

      Router.push("/");
    }
  } catch (err) {
    yield put(initializeActions.logoutFailure(err));
  }
}

export function* pageTemplateReq(action: any) {
  try {
    yield fork(
      API.get(
        contentTemplate.getAllTemplate,
        initializeActions.getContentTemplateSucc,
        initializeActions.getContentTemplateFail
      )
    );
  } catch (err) {
    yield put(initializeActions.getContentTemplateFail(err));
  }
}

export function* getExchangeRateReq(action: any) {
  try {
    yield fork(
      API.get(
        currencyApi +
          mapCryptoCoinId[localStorage.getItem("currency").toUpperCase()],
        initializeActions.getContentTemplateSucc,
        initializeActions.getCurrencyExchangeFail
      )
    );
  } catch (err) {
    yield put(initializeActions.getCurrencyExchangeFail(err));
  }
}

export function* getUserDataReq(action: any) {
  try {
    if (tokenId()._id) {
      const response = yield fork(
        API.get(
          onBoarding.getUserInfo + tokenId()._id,
          initializeActions.getUserDataSucc,
          initializeActions.getUserDataFail,
          localStorage.getItem("tokenBased")
        )
      );
      if (response) {
        const error = yield take(initializeActions.getUserDataFail);
        if (error.payload.status !== 200) {
          localStorage.removeItem("tokenBased");
          localStorage.removeItem("guest_token");
          document.cookie = `tokenBased=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;
          document.cookie = `guest_token=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;
          Router.push("/");
        }
      }
    }
  } catch (err) {
    yield put(initializeActions.getUserDataFail(err));
  }
}

export function* addRemoveWishListReq(action: any) {
  const { type, payload } = action.payload;
  try {
    if (type === "remove") {
      yield fork(
        API.put(
          `search-history/${payload.hotel_id}?wishlist=true`,
          initializeActions.wishListAddRemoveSucc,
          initializeActions.wishListAddRemoveFail,
          {},
          localStorage.getItem("tokenBased")
        )
      );
    } else {
      yield fork(
        API.post(
          wishList.addWishList,
          initializeActions.wishListAddRemoveSucc,
          initializeActions.wishListAddRemoveFail,
          payload,
          localStorage.getItem("tokenBased"),
          "POST"
        )
      );
    }
  } catch (err: any) {
    yield put(initializeActions.wishListAddRemoveFail(err));
  }
}

export function* getUserAddedWishList(action: any) {
  try {
    yield fork(
      API.get(
        wishList.getUserWishList,
        initializeActions.getUserAddedWishListSucc,
        initializeActions.getUserAddedWishListFail,
        localStorage.getItem("tokenBased")
      )
    );
  } catch (err: any) {
    yield put(initializeActions.getUserAddedWishListFail(err));
  }
}

export function* getEventListReq(action: any) {
  try {
    yield fork(
      API.get(
        eventList.getAllEvent,
        initializeActions.getEventListSucc,
        initializeActions.getEventListFail,
        localStorage.getItem("tokenBased")
      )
    );
  } catch (err: any) {
    yield put(initializeActions.getUserAddedWishListFail(err));
  }
}

export function* getRecentHotelSearch(action: any) {
  try {
    yield fork(
      API.get(
        search.getRecentHotelSearch + tokenId()._id,
        initializeActions.getHotelRecentSearchSucc,
        initializeActions.getHotelRecentSearchFail,
        "",
        "GET"
      )
    );
  } catch (err) {
    yield put(initializeActions.getHotelRecentSearchFail(err));
  }
}

export function* getFlightRecentSearch(action: any) {
  try {
    yield fork(
      API.get(
        search.getFlightRecentSearch + tokenId()._id,
        initializeActions.getFlightRecentSearchSucc,
        initializeActions.getFlightRecentSearchFail,
        "",
        "GET"
      )
    );
  } catch (err) {
    yield put(initializeActions.getFlightRecentSearchFail(err));
  }
}

export function* initalizeSaga() {
  yield takeLatest(initializeActions.getCryptoListReq.type, getCryptoListReq);
  yield takeLatest(initializeActions.logoutRequest.type, logoutRequest);
  yield takeLatest(
    initializeActions.getContentTemplateReq.type,
    pageTemplateReq
  );
  yield takeLatest(
    initializeActions.getCurrencyExchangeReq.type,
    getExchangeRateReq
  );
  yield takeLatest(initializeActions.getUserDataReq.type, getUserDataReq);
  yield takeLatest(
    initializeActions.wishListAddRemoveReq.type,
    addRemoveWishListReq
  );
  yield takeLatest(
    initializeActions.getUserAddedWishListReq.type,
    getUserAddedWishList
  );
  yield takeLatest(initializeActions.getEventListReq.type, getEventListReq);
  yield takeLatest(
    initializeActions.getHotelRecentSearchReq.type,
    getRecentHotelSearch
  );
  yield takeLatest(
    initializeActions.getFlightRecentSearchReq.type,
    getFlightRecentSearch
  );
}
