import Spinners from "comp/Spinner";
import AlertMessage from "comp/Alert";
import { Button } from "flowbite-react";
import LoginByGoogle from "comp/Sociallogin/googleLogin";
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_SITE_KEY } from "constants/constants";
import React from "react";
import PasswordInputField from "comp/FormComponents/passwordField";
import InputForm from "src/components/FormComponents/inputForm";

interface Props {
  data?: any;
  errors: any;
  isLoading: any;
  isHomePage: any;
  seterrors: any;
  recaptchaRef: any;
  handleChange: any;
  handleSubmit: any;
  addClassName?: any;
  handleRecaptcha: any;
  handleGoogleLogin: any;
  handleForgotPassword: any;
}

const LoginForm = (props: Props) => {
  const {
    data,
    errors,
    seterrors,
    isLoading,
    isHomePage,
    recaptchaRef,
    addClassName,
    handleChange,
    handleSubmit,
    handleRecaptcha,
    handleGoogleLogin,
    handleForgotPassword,
  } = props;
  return (
    <div className="">
      <div className="p-5 card-body">
        <div className="w-full border-opacity-50">
          {/* <h3 className="hidden lg:block">Login to your account.</h3> */}
          <div className="register-external ">
            <LoginByGoogle
              seterrors={seterrors}
              handleLogin={handleGoogleLogin}
            />
          </div>
          <div className="register-divider my-5 !text-xs dark:text-dark-text-primary">
            OR
          </div>
          <AlertMessage message={errors.errResp} type={"error"} />
          <div className="register-input ">
            <div className="">
              <label className="!p-0 flex dark:text-dark-text-primary">
                Email
              </label>
              <InputForm
                disabled={isLoading}
                type="email"
                placeholder="Email"
                name="username"
                value={data.username}
                onChange={(e) => handleChange(e)}
                // className="w-full text-sm text-gray-700 bg-white border-gray-200 rounded-md shadow-sm undefined"
              />
              <AlertMessage message={errors.username} type={"error"} />
            </div>
          </div>
          <div className="register-input register-input-detailPage ">
            {/* <div className="">
              <label className="block">
                <span className="label-text">Password</span>
              </label>
              <input
                disabled={isLoading}
                type="password"
                placeholder="Password"
                name="password"
                value={data.password}
                onChange={(e) => handleChange(e)}
                className="w-full"
              />
              <AlertMessage message={errors.password} type={"error"} />
            </div> */}

            <PasswordInputField
              data={data}
              errors={errors}
              maxLength={30}
              name={"password"}
              label="Password"
              placeholder="Password"
              handleChange={handleChange}
              fontSize={`text-base`}
            />
          </div>
          {isHomePage && (
            <label className="">
              <a
                href="#"
                onClick={() => handleForgotPassword()}
                className="label-text-alt link link-hover dark:text-dark-text-primary"
              >
                Forgot password?
              </a>
            </label>
          )}
          <div className="mt-2.5">
            <ReCAPTCHA
              sitekey={RECAPTCHA_SITE_KEY}
              onChange={(v) => handleRecaptcha(v)}
              onExpired={handleRecaptcha}
              ref={recaptchaRef}
            />
            <AlertMessage message={errors.reCaptcha} type="error" />
          </div>
          <div className="mt-6">
            <Button
              disabled={isLoading || data.password.length < 4 || !data.username}
              type="submit"
              onClick={handleSubmit}
              className={`w-full !py-1 button-flight !rounded-md  ${
                isLoading && "loading"
              }`}
            >
              <Spinners
                isLoading={isLoading}
                alternateName={"Login to XcelTrip"}
              />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
