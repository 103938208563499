import { useEffect } from "react";

const useAddClassName = (
  addClassName: any = false,
  className = "spinner-scroll-off"
) => {
  useEffect(() => {
    if (!addClassName) {
      document.body.classList.remove(className);
    }
    if (addClassName) {
      document.body.classList.add(className);
    }
    return () => {
      document.body.classList.remove(className);
    };
  }, [!addClassName]);

  const addClassNameonBody = () => {
    document.body.classList.add(className);
  };
  return [addClassNameonBody];
};

export default useAddClassName;
