import { URL } from "./constants";

export const getCryptoList = "pg/v1/supported-coins/available";

export const subscribeNewLetter = "newsletter/subscribe";

export const wishList = {
  getUserWishList: "search-history?wishlist=true",
  addWishList: "search-history?wishlist=true",
  // removeWishList: "search-history/${action.hotel_id}?wishlist=true",
};

export const onBoarding = {
  loginAPi: "login",
  logoutAPi: "user/logout",
  setPassword: "social-account/accept-terms-conditions/" /* SEND USER ID */,
  forgotPassword: "user/security-settings/reset-password-link",
  getStatusOfId:
    "password-reset/user/" /* GET  SEND ID */ /* USED FOR RESET PASSWORD */,
  changePassword:
    "user/change-password/confirm/" /* POST  NEED TO SEND TOKEN */,
  getUserInfo: "user/data/" /* send uuid */,
  verifyTfaCode: "multi-factor-auth/totp-validate/" /*  POST  SEND USED ID */,
};

export const register = {
  registerAPi: "user/data",
  getAllCountry: "configuration/features/countries",
  confirmUserRegister:
    "confirm/user/" /* GET  , SEND ID IN QUERY EG 123456789*/,
};

export const socialSignup = {
  api: "social-account/auth/google/",
};

export const userProfile = {
  getUserProfileDetails: "user/data/" /* need to send :id */,
};
	// https://www.xceltrip.com/v1/api/air/data/city/du
export const search = {
  hotelSearchApi: "expose-data/city?text=",
  flightSearchApi:  URL+ "v1/api/air/data/city/" /* PASS STRING AFTER CITY/ */,
  /* GET RECENT SEARCHES */
  getRecentHotelSearch: URL + "v1/api/hotelBe/search/recentSearch?user_id=",
  getFlightRecentSearch: URL + "v1/api/air/analytic/recentSearch?user_id=",
};

export const searListApi = {
  hotelSearchApi: URL + "v1/api/hotelBe/search/hotels",
  searchBySpecifiHotel: URL + "v1/api/hotelsData/pull/hotels",
  getHotelRoomLeftDetails: URL + "v1/api/hotelBe/search/rooms?search_identity=",
  getHotelDetailsById: URL + "v1/api/hotelsData/pull/hotels?hotel_id=",
};

export const flightApi = {
  flightSearchApi: URL + "v1/api/air/search/flight",
  searchBySpecifiHotel: URL + "v1/api/hotelsData/pull/hotels",
  singleFlightDetailApi: URL + "v1/api/air/search/flightDetails",
  flightFareRule: URL + "v1/api/air/fareRules",
  flightBaggageInfo: URL + "v1/api/air/baggageInfo",
  flightAirlineData: URL + "v1/api/air/data/airLines",
  flightCreateItinerary: URL + "v1/api/air/itinerary/create",
  promoCodeCalc: "promo/promocode-calculation",
  flightBookingUser: "user/data/flight-booking-user",
  priceCheckApi: "v1/api/air/itinerary/priceCheck",
  getBookingStatus: URL + "v1/api/air/itinerary/bookingStatus?ref_id=",
};

export const shareAndEarn = {
  postRecommendedData: "recommendation",
  getRecommendationType: "recommendation/types",
  getRecommendedList: "ecommendation/resource-list?resource_type=HOTEL",
  updateRecommendation:
    "recommendation?recommendation_id=" /* ADD RECOMMEND ID */,
};

export const contentTemplate = {
  getAllTemplate: "content-template/all",
};

export const hotelDetailsApi = {
  getHotelRoomLeftDetails: URL + "v1/api/hotelBe/search/rooms",
  requestBeforeConfirmationPage: "expose-data/bookingdetail",
};

export const eventList = {
  getAllEvent: "promo/event?page=1&perpage=100",
  getEventById: "promo/event?event_id=" /* SEND EVENT ID */,
};

export const confirmationPage = {
  getPgStatus: "pg-status",
  bookHotel: URL + "v1/api/hotelBe/booking/roomBlock" /* POST */,
  getUserIPDetails: "location/country",
  getBookingDetails: "expose-data/bookingdetail/" /* add the id */,
  getAllCountryList: "configuration/features/countries",
  promoCodeApi: "promo/promocode-calculation",
  getPaymentStatus:
    URL + "v1/api/hotelBe/booking/paymentStatus?ref_id=" /* GET  SEND REF ID */,
  bookingSuccess: URL + "v1/api/hotelBe/booking/bookingList?ref_id=",
};

export const userDashboard = {
  changePassword:
    "user/security-settings/change-password/" /* NEED TO SEND UUID */,
  getUserMobileInfo: "user/mobile",
  sendOTPVerificationToken: "user/sms/verification-token" /* POST  */,
  verifyUserOtp: "user/validate/number" /* POST  (FOR MOBILE VERIFICATION) */,
  removeMobileNumber: "user/mobile" /*  */,

  /* TWO FACTOR AUTH */
  getQrCode: "multi-factor-auth/totp-setup" /* GET  */,
  verifyQrCode: "multi-factor-auth/totp-verify?secret=" /* POST  */,
  sendBackupCode: "multi-factor-auth/recovery-code/send/" /* GET (USER ID) */,
  generateNewCodes:
    "multi-factor-auth/generate/recovery-code/" /* PUT (USER ID) */,
  disabledTfa: "multi-factor-auth/totp-disable/" /* PUT (USED ID) */,
  getTfAStatus: "multi-factor-auth/recovery-code/get" /* GET */,
  /* END TWO FACTOR AUTH */
};

export const newsLetter = {
  unsubscribed: "newsletter/unsubscribe/" /* POST send id */,
};
export const findItinerary = {
  /* HOTEL SECTION */
  getConfrimationCode: "itinerary",
  getUserHotelBooking: URL + "v1/api/hotelBe/booking/bookingList?user_id=",
  getBookingInformation:
    URL +
    "v1/api/hotelBe/booking/bookingList" /* ?ref_id=&auth_token=&email= */,
  getHotelDetails:
    URL + "v1/api/hotelsData/pull/hotels?hotel_id=" /* 5df927745340354cb35b369c */,
  cancelledHotelReq: URL + "v1/api/hotelBe/booking/cancelBooking?ref_id=",
  getCancellationCharge:
    URL +
    "v1/api/hotelBe/booking/cancellationCharges?ref_id=" /* XCLHE1675321069877 */,

  /* FLIGHT SECTION */
  getFlightConfirmationCode:
   URL+ "v1/api/air/analytic/itineraryToken" /* ?emailId=chhetriom01@gmail.com&referenceId=XCLF87598 */,
  getConfirmationFlighCode:
    URL + "v1/air/analytic/itinerary?token=${code}&referenceId=${ref_id}",
  getUserFlightBooking: URL + "v1/api/air/analytic/itinerary?userId=",
  verifyConfirmationFlightBooking:URL+
    "v1/api/air/analytic/itinerary" /* ?token=12345&referenceId=XCLF87598 */,
  getCancelButtonStatus:
    URL + "v1/api/air/analytic/check/cancel/button?referenceId=",
};

export const impDashboard = {
  /* MY-VENDORS */
  loadVerifiedVendorsList:
    "property/imp/verified" /* ?name=&email=&page=&perpage= */,
  loadRequestedVendorsList:
    "property/imp/request" /* ?name=&email=&page=&perpage= */,
  getPropertyType: "configuration/features/typeofproperty",
  createVendors: "property/imp/request",
  getVendorDetails: "property/imp/property-info/" /* vendor id */,

  /* MY-EARNINGS */
  getMyEarnings: "reward/api/earnings" /* ?page=1&perpage=10 */,

  /* RECOMMENDATION */
  getRecommendationList:
    "recommendation/reward-list" /* ?page=1&perpage=10&resource_type=HOTEL */,

  /* MY IMP */
  getImpRegisteredList:
    "imp/referral/registered-imps" /* ?name=&email=&page=1&perpage=10 */,
  getImpReferedList:
    "imp/referral/request" /* ?name=&email=&page=1&perpage=10 */,
  createReferImp: "imp/referral/request" /* CREATE REFER IMP */,
  getImpApplicantsDetails: "imp/applicant/detail/data/" /* APPLICANTS ID */,
  resendImpEmail: "imp/applicant/resendmail/" /* APPLICANTS ID */,

  /* IMP EARNINGS */
  getOffferXcelTokens: "offer-xcel-tokens",
  getOfferXcelList: "offer-xcel-tokens/list",
  getImpTokenTransfer: "imp-token-transfer/wallet-transfer-status/check",
  verificationStatus: "imp/check/verification/status/5bff9662b59a8b63de1c75bc",

  /* SHARE AND EARN */
  loadRecommendedToken: "recommendation",
  getShareEarnList: "recommendation/share-earn/date?date=",

  /* GETTING STARTED */
  getCategories: "knowledge-base/categories?active=true",
  getSubcategories: "knowledge-base/subcategories?active=true",
  getArticle: "knowledge-base/article?active=true",

  /* IMP STATUS */
  impVerification: "imp/check/verification/status/" /* user uuid */,
  getImpHistory: "imp/applicant/record",
  getImpDoc: "imp/docs-upload/allow/" /* user uuid */,
  uploadImage: "imp/applicant/verification/request",

  /* IMP-DASHBOARD */
  getOffferXcelToken: "offer-xcel-tokens",
  getApplicantHistory: "imp/applicant/record",
  getApplicatData: "imp/applicant/data/" /* user id */,
  getRefferalCode: "imp/fetch/referral/code/" /* user id */,

  /* DOWNLOAD FILE */
  downloadFile: "imp/document/signed-url",
};

/* MEDIA COVERAGE */
export const mediaCoverage = {
  getPressData: "press/news-info/details",
};

export const anonymousAgentRequest = "imp/new/referral/"; /* REFERAL ID */
export const getSmSForAnonymousAgent = "user/sms/verification-token/anonymous";

/* BLOG SECTION */
export const blog = {
  getArchiveCount: "blog/archivecount",
  getCategoryCount: "blog/categorycount",
  getMostReadBlog: "blog?mostread=true&page=1&perpage=5",
  getFeaturedBlog: "blog?featured=true&page=1&perpage=5",
  getArchiveBlog: "blog?page=1&perpage=6&date=",
  getAllBlog: "blog?page=1&perpage=6",
  getBlogByCategories: "blog?page=1&perpage=6&category_id=",
  getBlogDetails: "blog/" /* SLUG */,
  getAllComment: "blog/" /* blog/slug/comment */,
};

/* FAQ SECTION */
export const faq = {
  getFaqCategory: "faq/category",
  getFaqList: "faq",
  requestFeedback: "faq/rate/" /* topic id */ /* METHOD PUT */,
};

export const excludeRoute = [
  onBoarding.loginAPi,
  onBoarding.getUserInfo,
  confirmationPage.promoCodeApi,
  userDashboard.changePassword,
  onBoarding.changePassword,
  flightApi.promoCodeCalc,
  findItinerary.getFlightConfirmationCode,
  register.registerAPi,
];

export const contact = {
  postContact: "support-feedback/data",
};

/* 
https://www.jaamna.com/v1/api/
*/

export const promoCode = {
  getPromoCode: "promo/filter/" /* id?type=  hotel/flight/all */,
};
