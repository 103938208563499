import { Button } from "flowbite-react";
import { useState } from "react";
import InputForm from "src/components/FormComponents/inputForm";
import Spinners from "src/components/Spinner";
import { isEmail } from "utils/validation";
import AlertMessage from "comp/Alert";

interface Props {
  error: any;
  isRequesting: any;
  forgotPassword: any;
  responseMessage: any;
}

const ForgotPassword = ({
  error,
  responseMessage,
  isRequesting,
  forgotPassword,
}: Props) => {
  const [email, setEmail] = useState<any>("");
  const [errors, seterrors] = useState<any>({});
  const handleChange = (event: any) => {
    delete errors[event.target.name];
    setEmail(event.target.value);
  };
  const handleSubmit = () => {
    if (isEmail(email)) {
      forgotPassword(email);
    } else {
      seterrors({ email: "Invalid Email" });
    }
  };
  return (
    <div>
      Don’t worry! Just fill in your email and we’ll send you a password reset
      link.
      <div className="register-input">
        <InputForm
          name="email"
          errors={errors}
          label="email"
          showSearchIcon={false}
          value={email}
          placeholder="Email"
          handleChange={handleChange}
        />
        <AlertMessage
          message={error || responseMessage}
          type={error ? "error" : "success"}
        />
      </div>
      <Button
        className="text-right"
        disabled={isRequesting}
        onClick={() => handleSubmit()}
      >
        <Spinners isLoading={isRequesting} alternateName="Submit" />
      </Button>
    </div>
  );
};

export default ForgotPassword;
