import React, { useState, useEffect, useRef } from "react";
import Router from "next/router";
import { registerSaga } from "./saga";
import SignupForm from "./components/sign-upForm";
import { defaultRequired } from "./messageConfig";
import { fullName, isEmail, trimSpace } from "utils/validation";
import { useSelector, useDispatch } from "react-redux";
import { sliceKey, reducer, registerActions, initialState } from "./slice";
import {
  selectError,
  selectLoading,
  selectSuccessMessage,
  selectCountryList,
} from "./selectors";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";

declare global {
  interface Window {
    grecaptcha: any;
  }
}
interface Props {
  inPopup?: any;
}
const Signup = ({ inPopup = false }: Props) => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: registerSaga });
  const errorResponse = useSelector(selectError);
  const isLoading: any = useSelector(selectLoading);
  const countryList: any = useSelector(selectCountryList);
  const successMessage = useSelector(selectSuccessMessage);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(registerActions.getAllCountryReq());
    return () => {
      dispatch(registerActions.clearRegisterState());
    };
  }, []);
  const intialState = {
    address_city: "",
    address_country: "",
    agree_terms_condition: true,
    email: "",
    email_offer_subscription: true,
    first_name: "",
    last_name: "",
    password: "",
    reCaptcha: "",
    gender: "",
  };
  const recaptchaRef = React.createRef<any>();

  const [data, setdata] = React.useState<any>(intialState);
  const [errors, seterrors] = React.useState<any>({});
  const phoneAndDoBRef = useRef<HTMLSpanElement>(null);
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);

  const isFocusable = (name: string) => {
    const keys = Object.keys(errors);
    // focus when the field is first in order after submit btn click
    if (keys[0] === name && submitButtonClicked) {
      setSubmitButtonClicked(false);
      return true;
    }
    // scroll if there is error in dob or phone
    if (keys[0] === "mobile_number" || keys[0] === "address_country") {
      phoneAndDoBRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }

    return false;
  };

  React.useEffect(() => {
    if (errorResponse) {
      recaptchaRef?.current?.reset();
      seterrors({ ...errors, errResp: errorResponse });
    }
    if (successMessage) {
      setdata(initialState);
      setTimeout(() => {
        Router.push("/");
      }, 4000);
    }
  }, [errorResponse, successMessage]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    delete errors[name];
    if (errors.errResp) delete errors["errResp"];
    switch (name) {
      case "agree_terms_condition":
      case "email_offer_subscription":
        setdata({ ...data, [name]: !data[name] });
        break;
      case "password":
      case "address_city":
        setdata({ ...data, [name]: value });
        break;
      default:
        setdata({ ...data, [name]: trimSpace(value) });
        break;
    }
  };
  const validation: any = () => {
    let err: any = {};
    const checktype: any = {
      email: isEmail,
      first_name: fullName,
      last_name: fullName,
    };
    defaultRequired.map((eachReq: any) => {
      if (!trimSpace(data[eachReq.name])) err[eachReq.name] = eachReq.message;
      if (checktype[eachReq.name] && eachReq.afterData && !data[eachReq.name]) {
        if (!isEmail(data.email)) err.email = "Invalid email";
        if (!fullName(data.first_name)) err.first_name = "Invalid first name";
        if (!fullName(data.last_name)) err.last_name = "Invalid last name";
      }
    });
    return err;
  };
  const handleRecaptcha = (value: any) => {
    if (errors.reCaptcha) delete errors["reCaptcha"];
    setdata({ ...data, reCaptcha: value });
  };
  const checkAndSubmit = (event: any) => {
    setSubmitButtonClicked(true);
    handleSubmit(event);
  };
  const handleSubmit = (event: any) => {
    const err = validation();
    seterrors(err);
    if (Object.keys(err).length === 0) {
      dispatch(registerActions.registerRequest({ ...data, inPopup }));
      // window.grecaptcha.reset();
      setdata({ ...data, reCaptcha: "" });
    }
  };
  const handleGoogleLogin = (accessToken: any) => {
    dispatch(
      registerActions.registerRequest({
        token: accessToken,
        socialLogin: true,
        inPopup,
      })
    );
  };
  return (
    <SignupForm
      data={data}
      errors={errors}
      inPopup={inPopup}
      seterrors={seterrors}
      isLoading={isLoading}
      countryList={countryList}
      recaptchaRef={recaptchaRef}
      handleChange={handleChange}
      handleSubmit={checkAndSubmit}
      successMessage={successMessage}
      handleRecaptcha={handleRecaptcha}
      handleGoogleLogin={handleGoogleLogin}
      isFocusable={isFocusable}
    />
  );
};

export default Signup;
