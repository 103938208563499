import { take, takeLatest, fork, put, select, delay } from "redux-saga/effects";
import API from "utils/apiHelper";
import { registerActions as actions } from "./slice";
import Router from "next/router";
import { register, socialSignup } from "constants/apiConfig";
import { initializeActions } from "src/Initialize/slice";
/**
 * Github repos request/response handler
 */

export function* registerRequest(action: any) {
  const { payload } = action;
  try {
    const resisterResp: any = yield fork(
      API.post(
        payload.socialLogin
          ? socialSignup.api + payload.token
          : register.registerAPi,
        actions.registerSuccess,
        actions.registerFailure,
        payload,
        "",
        "POST"
      )
    );

    if (resisterResp) {
      const resp = yield take(actions.registerSuccess);
      console.log(resp, "FROM THE SAGA", payload);
      if (
        resp.payload.data.first_attempt_social_login ||
        resp.payload.data.password_set === false
      ) {
        yield put(
          initializeActions.showDialog({
            showModal: true,
            mapComp: "passwordSet",
            title: "SET YOUR KEYS",
            serverResponse: resp.payload?.data,
            addClassName: "reactmodal-modify-3",
            email: resp.payload?.data?.email || "",
          })
        );
      } else if (resp.payload?.data?.multi_factor_auth_enable) {
        yield put(
          initializeActions.showDialog({
            showModal: true,
            mapComp: "2fa",
            title: "Multi Factor Auth",
            serverResponse: resp.payload?.data,
            addClassName: "reactmodal-modify-3",
            email: resp.payload?.data?.email || "",
          })
        );
      } else if (resp?.payload?.data?.token && payload?.inPopup) {
        yield put(
          initializeActions.getUserDataSucc({
            data: resp?.payload?.data?.userInfo,
          })
        );
        yield put(initializeActions.showDialog());
      } else if (
        payload?.inPopup &&
        (resp?.payload?.data?.userInfo?._id || resp?.payload?.data?._id)
      ) {
        yield put(
          initializeActions.showDialog({
            showModal: true,
            mapComp: "login",
            serverResponse: resp.payload?.data,
            addClassName: "reactmodal-modify-3",
            email: resp?.payload?.data?.email,
          })
        );
      } else {
        Router.push("/");
      }
    }
  } catch (err) {
    yield put(actions.registerFailure(err));
  }
}

export function* getCountryList(action: any) {
  try {
    yield fork(
      API.get(
        register.getAllCountry,
        actions.getAllCountrySucc,
        actions.getAllCountryFail,
        "",
        "GET"
      )
    );
  } catch (err) {
    yield put(actions.getAllCountryFail(err));
  }
}

export function* registerSaga() {
  yield takeLatest(actions.registerRequest.type, registerRequest);
  yield takeLatest(actions.getAllCountryReq.type, getCountryList);
}
