import Image from "next/image";
import { useRouter } from "next/router";
import Logo from "image/navbar/XceltripMain.svg";
import whiteLogo from "image/navbar/XceltripMain-dark.svg";

import { Icon } from "@iconify/react";
import QRcode from "image/navbar/xcelpayqr.png";
import { useEffect } from "react";
import Gplay from "image/navbar/gplay.png";
import appStore from "image/navbar/appstore.png";
import Link from "next/link";
import useByPassBackButton from "customHooks/useByPassBackButton";
import { useTheme } from "src/contexts/themeContext";

const MobileSideNavigationHeader = ({
  passRef,
  SignupButtons,
  isComponentVisible,
  setIsComponentVisible,
  isUserLogin,
}: any) => {
  const router = useRouter();
  useByPassBackButton({
    isShown: isComponentVisible,
    setisShown: setIsComponentVisible,
  });

  const { darkMode } = useTheme();

  return (
    <>
      <div
        // ref={passRef}
        className={` ${
          isComponentVisible ? " pointer-events-none " : "hidden "
        } fixed bg-[rgba(0,0,0,0.3)] top-0 right-0 bottom-0 left-0 
          ${router.pathname === "/" ? " z-[0] " : "z-[2]"}
          `}
      ></div>
      <div
        ref={passRef}
        className={`
                ${isComponentVisible ? " " : " translate-x-[100%] "}
              bg-white dark:bg-dark-bg-secondary w-[330px] rounded-tl-[8px] rounded-bl-[8px] fixed h-full z-[0] top-0 right-0 bottom-0 overflow-hidden
              transition-all show-Nav ${
                router.pathname === "/" ? " z-[0] " : "z-[2]"
              } `}
      >
        <div className="pt-3 pb-8 mx-8 border-b dark:border-gray-700">
          <div className="flex items-center justify-between">
            <Image
              src={darkMode ? whiteLogo : Logo}
              alt="xceltrip"
              // placeholder="blur"
              className="self-center mr-3 w-28 lg:w-44 "
            />

            <div onClick={() => setIsComponentVisible(!isComponentVisible)}>
              <Icon
                icon={`solar:close-square-outline`}
                className={`  w-7 h-7 text-gray-600 dark:text-dark-text-primary `}
              />
            </div>
          </div>
        </div>
        <div className="p-8 pt-8">
          <div className="flex flex-col-reverse gap-3 ">
            <details
              className={`${
                router.pathname == "/"
                  ? "text-black dark:text-dark-text-primary "
                  : " text-black"
              } `}
            >
              <summary className="text-sm">Bookings</summary>
              <Link href="/my-bookings" className="text-sm">
                {" "}
                Hotel Booking
              </Link>
              <Link href="/my-bookings?q=flight" className="text-sm">
                Flight Booking
              </Link>
            </details>
            <div className="">
              <Link
                className="text-sm text-black dark:text-dark-text-primary"
                href="/listyourtoken
                "
              >
                + List Your Token
              </Link>
            </div>
            <div
              className={`m-0 list-none p_2   ${
                isUserLogin
                  ? " flex justify-between items-center"
                  : "text-center flex flex-col gap-3"
              }`}
            >
              {SignupButtons}
            </div>
            <div className="order-first">
              <p className="p-1 pt-2 mb-1 text-sm text-center text-black dark:text-dark-text-primary">
                Download XcelPay App to get exciting discounts
              </p>
              <a
                href="https://onelink.to/xcelpay"
                target="_blank"
                rel="noreferrer"
              >
                <Image
                  className="img-fluid w-[110px] mx-auto"
                  src={QRcode}
                  alt="qr"
                />
              </a>
              <div className="flex justify-center gap-1 py-2">
                <a
                  href="https://apps.apple.com/us/app/xcelpay-secure-crypto-wallet/id1461215417"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    className="w-24 h-8 mx-auto mb-1 "
                    src={appStore}
                    alt="appstore"
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.XcelTrip.XcelPay&hl=en&gl=US"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    className="w-24 h-8 mx-auto mb-1"
                    src={Gplay}
                    alt="playstore"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileSideNavigationHeader;
