import React, { useEffect } from "react";
import AlertMessage from "comp/Alert";

interface Props {
  type?: string;
  label: string;
  placeholder: string;
  name: string;
  value: string;
  errors?: any;
  showErrorLable?: any;
  handleChange: any;
  disabled?: any;
  maxLength?: any;
  hasError?: any;
  errorName?: string;
  isFocusable?: any;
  autoComplete?: any;
  showSearchIcon?: any;
  showAutoFocus?: any;
  labelClass?: any;
  className?: any;
  iconClass?: any;
}
const InputForm = (props: Props) => {
  const {
    type = "text",
    label,
    errors = {},
    name,
    maxLength,
    value,
    placeholder,
    handleChange,
    errorName = "",
    disabled = false,
    hasError = false,
    showErrorLable = true,
    isFocusable,
    autoComplete,
    showSearchIcon = true,
    showAutoFocus = false,
    className,
    labelClass,
    iconClass,
  } = props;

  return (
    <div className={`form-control `}>
      <label className={`pb-1.5 text-sm flex ${labelClass} font-medium`}>
        {label}
      </label>

      <div className="relative group ">
        {showSearchIcon && (
          <div
            className={`${iconClass} flex search-icon absolute inset-y-0 left-0 top-5 items-center pointer-events-none transform duration-500`}
          >
            {/* <svg
              aria-hidden="true"
             
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg> */}

            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`
              ${errors[errorName || name] && " input-error "}
              w-[16px] h-[16px] text-gray-500 dark:text-gray-400`}
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <g fill="none" stroke="currentColor" strokeWidth="1.5">
                <path d="M4 10.143C4 5.646 7.582 2 12 2s8 3.646 8 8.143c0 4.462-2.553 9.67-6.537 11.531a3.45 3.45 0 0 1-2.926 0C6.553 19.812 4 14.605 4 10.144Z" />
                <circle cx="12" cy="10" r="3" />
              </g>
            </svg>
          </div>
        )}
        <input
          autoComplete={autoComplete}
          onChange={handleChange}
          name={name}
          type={type}
          maxLength={maxLength || 500}
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          className={`w-full  rounded-md ${className}   bg-white dark:bg-dark-bg-secondary text-sm text-gray-700 dark:text-dark-text-primary 
           focus:border-white border-gray-400  focus:ring-newPrimary-100 shadow-sm
          ${
            errors[errorName || name] &&
            "input-error !border-red-500 rounded-md !border-[1px]  "
          }`}
          ref={(input) =>
            input &&
            (((showAutoFocus && input?.focus({ preventScroll: true })) ?? "") ||
              (isFocusable && isFocusable(`${name}`) && input.focus()) ||
              "")
          }
          // ref={(input: any) => {
          //   if (showAutoFocus) {
          //     // input?.focus({ preventScroll: true, focusVisible: true }) ?? "";
          //     input?.focus({ preventScroll: true, focusVisible: true }) ?? "";
          //     // input?.focus();
          //     test();
          //   }
          // }}
        />
      </div>
      {showErrorLable && (
        <AlertMessage message={errors[errorName || name]} type={"error"} />
      )}
    </div>
  );
};

export default InputForm;
