import { createContext } from "react";

interface DefaultContextType {
  logoutReq?: any;
  isUserLogin?: boolean;
}
const defaultValue = {
  isUserLogin: false,
};
const Context: any = createContext<DefaultContextType | null>(defaultValue);

export default Context;
