import { useState } from "react";
import AlertMessage from "comp/Alert";
import { Button } from "flowbite-react";
import Spinners from "src/components/Spinner";
import { onlyNumber } from "src/utils/validation";
import InputForm from "src/components/FormComponents/inputForm";

interface Props {
  verifyTfa: any;
  isLoading: any;
  serverResp: any;
}

const TwoFactorAuth = ({ verifyTfa, isLoading, serverResp }: Props) => {
  const [errors, setErrors] = useState<any>({});
  const [data, setData] = useState<any>({});
  const validation = () => {
    let err: any = {};
    if (!data.totp_token) err.totp_token = "Enter your token";
    return err;
  };
  const handleChange = (event: any) => {
    const {
      target: { name, value },
    } = event;
    delete errors[name];
    /* onlyNumber(value) &&  */ setData({ [name]: value });
  };
  const handleSubmit = () => {
    const err = validation();
    setErrors(err);
    if (Object.keys(err).length === 0) {
      console.log("test");
      verifyTfa(data);
    }
  };
  return (
    <div>
      XCELTRIP Multi Factor Auth Login
      <div className="mt-2">Enter the token from your authenticator app</div>
      <div className="register-input mt-2 mb-4">
        <InputForm
          label=""
          maxLength={6}
          name="totp_token"
          errors={errors}
          disabled={isLoading}
          placeholder="Token..."
          handleChange={handleChange}
          value={data.totp_token || ""}
        />
        <AlertMessage message={serverResp} type={"error"} />
        <Button color="warning" className="mt-4" onClick={handleSubmit}>
          <Spinners
            isLoading={isLoading}
            alternateName="Submit"
            text="Loading"
          />
        </Button>
      </div>
    </div>
  );
};

export default TwoFactorAuth;
