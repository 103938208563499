import { useState, useEffect, useRef } from "react";
import { useRouter } from "next/router";

export default function useComponentVisible(
  initialIsVisible,
  hideOnClick
) {
  const router = useRouter();

  const [isComponentVisible, setIsComponentVisible] =
    useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    const origin = window.location.origin + router.pathname;
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
    if (hideOnClick && ref.current && event.target.href === origin) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (isComponentVisible && ref.current) {
      const component = ref.current;
      const rect = component.getBoundingClientRect();
      const componentHeight = rect.height;
      const visibleHeight =
        Math.min(window.innerHeight, rect.bottom) - Math.max(0, rect.top);
      const visibilityRatio = visibleHeight / componentHeight;
      if (visibilityRatio < 1) {
        // Component is not fully visible
        let scrollToOffset;
        if (window.innerWidth >= 1280) {
          scrollToOffset = rect.top - 230; // Offset for larger devices (e.g., 13" Mac)
        } else {
          scrollToOffset =
            rect.top - (window.innerHeight - componentHeight) / 2; // Default offset for other devices
        }
        window.scrollTo({ top: scrollToOffset - 130, behavior: "smooth" });
      }
    }
  }, [isComponentVisible]);

  return { ref, isComponentVisible, setIsComponentVisible };
}
