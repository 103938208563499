import { useCallback, useEffect, useState } from "react";

const BREAKPOINT = 768;

const useIsSmallDevice = (resetCb=()=>{}) => {
  const [isSmallDevice, setIsSmallDevice] = useState(false);

  const handleResize = useCallback(() => {
    if (window.innerWidth > BREAKPOINT) {
      setIsSmallDevice(false);
    } else {
      setIsSmallDevice(true);
    }
    resetCb()
  }, []);

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isSmallDevice;
};

export default useIsSmallDevice;
