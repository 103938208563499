import React, { useEffect } from "react";
import Header from "src/components/Layout/header";
import Head from "next/head";
import { URL } from "constants/constants";
import { AppleTouchIcon } from "./apple-touch-Icon";
import {
  jsonLdInitial,
  jsonLdTwo,
  jsonLdThree,
  jsonLdFour,
  jsonLdFive,
} from "./jsonLdConfig";
interface Props {
  title?: any;
  keywords?: any;
  children: any;
  shareImage?: any;
  description?: any;
  ogUrl?: any;
  jsonLd?: any;
  imageUrl?: any;
}

const Layout = (props: any) => {
  const baseUrl = URL.slice(0, -1);
  const {
    jsonLd,
    isHomePage = false,
    ogUrl = baseUrl,
    title = "XcelTrip: Your Gateway to Affordable Luxury Travel with Cryptocurrency!",
    keywords = "book hotel and flight with crypto, book hotel and flight using bitcoin, buy plane tickets with bitcoin, travel with crypto, travel by bitcoin",
    description = "Embark on the ultimate travel experience with Xceltrip. Our crypto-friendly booking platform allows you to explore top destinations, book over 2.3 million luxury hotels and 450 flights—all using your preferred cryptocurrency like BTC, ETH, USDT, XLD and more. Dive into a seamless travel adventure today!",
    shareImage = baseUrl + "/og_image/share_og_image.jpeg",
  } = props;
  useEffect(() => {
    document.documentElement.lang = "en-us";
  });
  return (
    <div>
      <Head>
        <title>{title}</title>
        <meta charSet="utf-8" />
        <title itemProp="name">{title}</title>
        <meta httpEquiv="content-language" content="en-us"></meta>
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="viewport"
          content="width=device-width"
          initial-scale="1.00"
          maximum-scale="1.0"
          user-scalable="no"
        />
        <link rel="canonical" href={URL} />
        <link rel="icon" type="image/png" href="/favicon.ico" sizes="any" />

        {isHomePage && (
          <link
            as="image"
            rel="preload"
            href="/image/homepage/hotel_bg.webp"
            type="image/webp"
          ></link>
        )}
        {isHomePage && (
          <link
            as="image"
            rel="preload"
            href="/image/homepage/flight_bgold.webp"
            type="image/webp"
          ></link>
        )}
        <link rel="icon" href="/apple_touch.png" type="image/svg+xml" />
        <AppleTouchIcon />
        <meta name="robots" content="max-image-preview:large" />
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta
          name="theme-color"
          media="(prefers-color-scheme: light)"
          content="#c7cfe2"
        />
        {/* OPEN GRAPH DATA */}
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:url" content={ogUrl} />
        <meta
          property="og:title"
          // content="XCELTRIP - Decentralized Travel Ecosystem"
          content={title}
        />
        <meta
          property="og:description"
          // content="XCELTRIP - Decentralized Travel Ecosystem"
          content={description}
        />
        <meta property="og:image" content={shareImage} />
        {/* <meta property="og:image:type" content="image/png" /> */}
        {/*  <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" /> */}
        <meta property="og:site_name" content="XCELTRIP" />
        {/* Twitter Card data  */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@XcelTrip" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={shareImage} />
        <meta property="twitter:url" content={ogUrl} />
        <meta name="twitter:image:alt" content={shareImage} />
        <meta name="google-site-verification" content="google" />
        <meta
          property="og:see_also"
          content="https://www.facebook.com/XcelTripGo"
        />
        <meta
          property="og:see_also"
          content="https://www.linkedin.com/company/xceltrip"
        />
        <meta
          property="og:see_also"
          content="https://www.instagram.com/xceltrip"
        />
        <link rel="manifest" href="/manifest.json" />
        {/* Schema.org markup for Google+ */}
        <meta itemScope itemType="http://schema.org/WebSite" />
        <meta itemProp="name" content="XCELTRIP" />
        <meta
          itemProp="description"
          content="Plan your next trip with XcelTrip via XcelToken Crypto. It’s simple, it’s faster and it’s easy to manage your Hotels Bookings. We are building the platform to Decentralize Travel Systems."
        />
        <meta itemProp="image" content={shareImage} />
        <link rel="assets" href="https://www.xceltrip.com/public" />
        {/* <!--  Non-Essential, But Required for Analytics --> */}
        {jsonLd && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
          />
        )}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLdInitial) }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLdTwo) }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLdThree) }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLdFour) }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLdFive) }}
        />
      </Head>
      <Header />

      {props.children}
    </div>
  );
};

export default Layout;
