import { Icon } from "@iconify/react";
import { Alert } from "flowbite-react";

interface Props {
  type: string;
  message: string;
  showIcon?: boolean;
}
const AlertMessage = ({ type, showIcon = false, message }: Props) => {
  return (
    <>
      {message && (
        <Alert
          className="!p-0 "
          color={`${type === "error" ? "failure" : "info"} `}
        >
          <p className="m-0 ">
            {(showIcon && (
              <Icon
                icon="ion:information-circle-outline"
                className="w-4 h-4 mr-1.5 text-red-500"
              />
            )) ||
              false}
            <p
              className={` m-0 text-vs ${type === "error" ? "text-red-500 " : "text-newText-100"
                }`}
            >
              {message}
            </p>
          </p>
        </Alert>
      )}
    </>
  );
};

export default AlertMessage;
