import { take, takeLatest, fork, put, select, delay } from "redux-saga/effects";
import API from "utils/apiHelper";
import { loginActions as actions } from "./slice";
import { onBoarding, socialSignup } from "constants/apiConfig";
import { initializeActions } from "../../Initialize/slice";
import { tokenId } from "utils/authorizationHelper";
import { selectModaProps } from "Initialize/selectors";
import Router from "next/router";

/**
 * Github repos request/response handler
 */
let tempData: any;

export function* loginRequest(action: any) {
  const { payload } = action;
  try {
    const loginResp: any = yield fork(
      API.post(
        payload.socialLogin
          ? socialSignup.api + payload.token
          : onBoarding.loginAPi,
        actions.loginSuccess,
        actions.loginFailure,
        payload,
        "",
        "POST"
      )
    );
    if (loginResp) {
      const resp = yield take(actions.loginSuccess);
      // console.log("<>", resp.payload);
      tempData = resp.payload?.data;
      if (resp.payload?.data?.first_attempt_social_login === true) {
        yield put(
          initializeActions.showDialog({
            showModal: true,
            mapComp: "passwordSet",
            title: "SET YOUR KEYS",
            addClassName: "reactmodal-modify-3",
            email: resp.payload?.data?.email || "",
          })
        );
      } else if (resp.payload?.data?.password_set === false) {
        yield put(
          initializeActions.showDialog({
            showModal: true,
            mapComp: "passwordSet",
            title: "SET YOUR KEYS",
            addClassName: "reactmodal-modify-3",
            email: resp.payload?.data?.email || "",
          })
        );
      } else if (resp.payload?.data?.multi_factor_auth_enable) {
        yield put(
          initializeActions.showDialog({
            showModal: true,
            mapComp: "2fa",
            title: "Multi Factor Auth",
            addClassName: "reactmodal-modify-3",
            email: resp.payload?.data?.email || "",
          })
        );
      } else {
        if (resp.payload?.data?.token) {
          const pathName = window.location.pathname;
          yield put(initializeActions.getUserDataReq());
          if (pathName !== "/login" && pathName !== "/sign-up") {
            yield put(initializeActions.showDialog());
          } else {
            yield put(initializeActions.showDialog());
            Router.push("/");
          }
        }
      }

      /* if (pathName === '/login') {
                Router.push('/')
            } else {
                Router.push(pathName)
            } */
    }
  } catch (err) {
    yield put(actions.loginFailure(err));
  }
}

export function* forgotPasswordReq(action: any) {
  const { payload } = action;
  try {
    yield fork(
      API.post(
        onBoarding.forgotPassword,
        actions.forgotPasswordSucc,
        actions.forgotPasswordFail,
        { email: payload },
        "",
        "POST"
      )
    );
  } catch (err: any) {
    yield put(actions.forgotPasswordFail(err));
  }
}

export function* setPasswordReq(action: any) {
  const { payload } = action;
  const retrivePayload = yield select(selectModaProps);
  tempData = retrivePayload.serverResponse || tempData;
  try {
    const passwordSetResp = yield fork(
      API.put(
        onBoarding.setPassword + (tempData.user_id || tempData._id),
        actions.setPasswordSucc,
        actions.setPasswordFail,
        payload,
        null,
        "PUT"
      )
    );
    if (passwordSetResp) {
      const responseData = yield take(actions.setPasswordSucc);
      if (responseData?.payload?.data?.token) {
        // yield put(initializeActions.getUserDataReq());
        yield put(
          initializeActions.getUserDataSucc({
            data: responseData.payload?.data?.userInfo,
          })
        );
        yield put(initializeActions.showDialog());
      }
    }
  } catch (err: any) {
    yield put(actions.setPasswordFail(err));
  }
}

export function* verifyTfaCodeReq(action: any) {
  const retrivePayload = yield select(selectModaProps);
  tempData = retrivePayload.serverResponse || tempData;
  try {
    yield fork(
      API.post(
        onBoarding.verifyTfaCode + tempData.user_id,
        actions.verifyTfaCodeSucc,
        actions.verifyTfaCodeFail,
        action.payload,
        "",
        "POST"
      )
    );
    const resp = yield take(actions.verifyTfaCodeSucc);
    if (resp.payload?.data?.token) {
      // yield put(initializeActions.getUserDataReq());
      yield put(
        initializeActions.getUserDataSucc({
          data: resp.payload?.data?.userInfo,
        })
      );
      yield put(initializeActions.showDialog());
    }
  } catch (err: any) {
    yield put(actions.verifyTfaCodeFail(err));
  }
}

export function* loginSaga() {
  yield takeLatest(actions.loginRequest.type, loginRequest);
  yield takeLatest(actions.forgotPasswordReq.type, forgotPasswordReq);
  yield takeLatest(actions.setPasswordReq.type, setPasswordReq);
  yield takeLatest(actions.verifyTfaCodeReq.type, verifyTfaCodeReq);
}
