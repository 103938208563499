import { Icon } from "@iconify/react";
import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import InputForm from "src/components/FormComponents/inputForm";
import useIsTouchDevice from "src/customHooks/useIsTouchDevice";
import useAddClassName from "src/customHooks/useAddBodyClassName";
import { useRouter } from "next/router";
import useByPassBackButton from "customHooks/useByPassBackButton";
import useIsSmallDevice from "src/customHooks/useIsSmallDevice";

interface FullscreenOnMobileProps {
  header: any;
  content: any;
  isShown: any;
  setisShown: any;
  className?: any;
  addClassOnBody?: any;
}
export const FullscreenOnMobile = ({
  header,
  content,
  isShown,
  setisShown,
  className,
  addClassOnBody,
}: FullscreenOnMobileProps) => {
  const router = useRouter();
  const isSmallDevice = useIsSmallDevice();
  const [] = useAddClassName(isSmallDevice && isShown);
  useByPassBackButton({ isShown, setisShown });
  return (
    <>
      {/* {alert(className)} */}
      <div
        className={`fullscreen_modal ${
          isShown && isSmallDevice ? `show ${className}` : ""
        } not-printable dark:bg-dark-bg-primary`}
      >
        <div className="p-5 pb-4 bg-white border-b rounded-t-lg fullscreen_modal_header dark:bg-dark-bg-primary dark:text-dark-text-primary dark:rounded-none dark:border-gray-500">
          {header}
        </div>

        <div
          className={`fullscreen_modal_scroll_area dark:bg-dark-bg-primary
            bg-white ${addClassOnBody}`}
        >
          {content}
        </div>
      </div>
    </>
  );
};
