import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'types'
import { initialState } from './slice'

// First select the relevant part from the state
const selectDomain = (state: RootState) => state?.register || initialState

export const selectRequesting = createSelector(
    [selectDomain],
    (registerState: { requesting: any }) => registerState.requesting,
)

export const selectSuccess = createSelector(
    [selectDomain],
    (registerState: { success: any }) => registerState.success,
)

export const selectError = createSelector(
    [selectDomain],
    (registerState: { error: any }) => registerState.error,
)

export const selectCountryList = createSelector(
    [selectDomain],
    (registerState: { countryList: any }) => registerState.countryList,
)

export const selectSuccessMessage = createSelector(
    [selectDomain],
    (registerState: { successMessage: any }) => registerState.successMessage,
)

export const selectLoading = createSelector(
    [selectDomain],
    (registerState: { requesting: any }) => registerState.requesting,
)
