import React from "react";
import { useRouter } from "next/router";

interface Props {
  setisShown: any;
  isShown: boolean;
}

const useByPassBackButton = ({ isShown, setisShown }: Props) => {
  const router = useRouter();
  React.useEffect(() => {
    if (process.env.MY_ENV /* !== "prod" */) {
      router.beforePopState(({ as }) => {
        if (as !== router.asPath) {
          if (isShown) {
            setisShown(false);
            window.history.pushState(null, "", router.asPath);
            return false;
          }
        }
        return true;
      });
    }
    return () => {
      router.beforePopState(() => true);
    };
  }, [router, isShown]);
};

export default useByPassBackButton;
