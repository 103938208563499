import { Spinner, Button } from "flowbite-react";
import React from "react";

interface Props {
  text?: string;
  isLoading?: boolean;
  alternateName?: any;
}
const Spinners = (props: Props) => {
  const { text, isLoading, alternateName } = props;
  return (
    <>
      {(isLoading && (
        <React.Fragment>
          <Spinner aria-label="Spinner button example" />
          {(text && <span className="pl-3">{text}...</span>) || false}
        </React.Fragment>
      )) ||
        alternateName}
    </>
  );
};
export default Spinners;
