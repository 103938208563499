import React, { useState, useEffect } from "react";
import AlertMessage from "comp/Alert";
import Select from "react-select";

interface Props {
  label?: string;
  placeholder?: string;
  name: string;
  value: string;
  errors?: any;
  handleChange: any;
  array: any;
  errorName?: any;
  isFocusable?: any;
  isSearchable?: any;
  disabled?: any;
}
const Selects = (props: Props) => {
  const {
    label,
    array,
    name,
    value,
    errors = {},
    placeholder,
    handleChange,
    errorName = "",
    isFocusable,
    isSearchable = true,
    disabled = false,
  } = props;

  const [structuredArray, setStructuredArray] = useState([]);
  useEffect(() => {
    if (array?.length) {
      setStructuredArray(
        array.map((eachArray: any) => ({
          ...eachArray,
          label: eachArray.text,
        }))
      );
    }
    return () => {
      setStructuredArray([]);
    };
  }, [array]);
  const [defaultValue, setDefaultValue] = useState([]);
  useEffect(() => {
    if (value && structuredArray) {
      setDefaultValue(
        structuredArray.filter((x: any) => x.value === value) || []
      );
    }
  }, [value, structuredArray]);

  return (
    <div className="form-control">
      <div className="pb-2 form-control custom-selectInput">
        {label && (
          <p className=" !mb-2  dark:text-dark-text-primary">{label}</p>
        )}
        <Select
          placeholder={placeholder}
          value={defaultValue}
          classNamePrefix="select"
          isDisabled={disabled}
          isLoading={false}
          isClearable={false}
          isSearchable={isSearchable}
          name="color"
          options={structuredArray}
          onChange={(e: any) =>
            handleChange({
              target: { name, value: e.value },
            })
          }
          ref={(input) =>
            isFocusable && input && isFocusable(`${name}`) && input.focus()
          }
          className={`w-auto cursor-pointer   select-bordered rounded-md border-0 text-base    ${
            errors[errorName || name] &&
            "select-error !border-red-500 !border-[1px]"
          }`}
        />
      </div>
      <AlertMessage message={errors[errorName || name]} type={"error"} />
    </div>
  );
};

export default Selects;
