import { toast } from "react-toastify";
import { ContainerState } from "./types";
import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { encodeData, decodedData } from "utils/encodeDecode";

export const initialState: ContainerState = {
  requesting: false,
  success: false,
  error: null,
  cryptoList: {},
  showModal: false,
  dialog: null,
  userData: {},
  accessToken: "",
  pageTemplate: {},
  exchangeRate: {},
  hotelCryptoList: {
    defaultCrypto: "",
    data: [],
  },
  flightCryptoList: {
    defaultCrypto: "",
    data: [],
  },
  fiatList: [],
  wishListResponse: {},
  userWishList: [],
  modalProps: {},
  eventList: [],
  defaultCurrency:
    (typeof window !== "undefined" &&
      localStorage.getItem("currency")?.toLowerCase()) ||
    "btc",
  recentFlightSearch: [],
  recentHotelSearch: [],
  wishListSuccess: false,
};

const initializeSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setDeafultCurrency(state: any, action: any) {
      document.cookie = `currency=${action.payload.toLowerCase()}; Max-Age=604800; path=/`;
      localStorage.setItem("currency", action.payload.toLowerCase());
      state.defaultCurrency = action.payload.toLowerCase();
    },
    getCryptoListReq(state: any) {
      state.requesting = false;
      state.error = null;
      state.cryptoList = {};
      state.fiatList = [];
      state.success = false;
      state.hotelCryptoList = initialState.hotelCryptoList;
      state.flightCryptoList = initialState.flightCryptoList;
    },
    getCryptoListSucc(state: any, action: PayloadAction<any>) {
      const responseData = action.payload;
      let hotelCrypto: any = [],
        flightCrypto: any = [];
      responseData?.data?.crypto?.map((eachCrypto: any) => {
        if (eachCrypto.forFlight) {
          flightCrypto.push(eachCrypto);
        }
        if (eachCrypto.forHotel) {
          hotelCrypto.push(eachCrypto);
        }
      });
      state.hotelCryptoList = {
        data: hotelCrypto,
        defaultCrypto: responseData.defaultForHotel,
      };
      state.flightCryptoList = {
        data: flightCrypto,
        defaultCrypto: responseData.defaultForFlight,
      };
      const defaultCurrency =
        decodedData(localStorage.getItem("fiat"), true) || {};
      const filterData = responseData?.data?.fiat.filter(
        (eachFiat: any) => eachFiat.value.toLowerCase() === "usd"
      );
      if (Object.keys(defaultCurrency).length === 0) {
        if (filterData.length) {
          localStorage.setItem("fiat", encodeData(filterData[0]));
        }
      } else if (Object.keys(defaultCurrency).length > 0) {
        const updateFiatLocalStorage = responseData?.data?.fiat.filter(
          (eachFiat: any) =>
            eachFiat?.value?.toLowerCase() ===
            defaultCurrency?.value?.toLowerCase()
        );
        if (updateFiatLocalStorage.length) {
          localStorage.setItem("fiat", encodeData(updateFiatLocalStorage[0]));
        } else {
          localStorage.setItem("fiat", encodeData(filterData[0]));
        }
      }
      state.fiatList = responseData?.data?.fiat || [];
      state.cryptoList = responseData;
      state.requesting = false;
      state.error = null;
      state.success = true;
    },
    getCryptoListFail(state: any, action: PayloadAction<any>) {
      const response = action?.payload?.data;
      state.cryptoList = "";
      state.error = response;
      state.requesting = false;
      state.success = false;
      state.fiatList = [];
      state.hotelCryptoList = initialState.hotelCryptoList;
      state.flightCryptoList = initialState.flightCryptoList;
    },
    logoutRequest(state: any) {
      state.success = false;
      state.error = "";
    },
    logoutSuccess(state: any) {
      state.error = "";
      state.userData = {};
      state.success = false;
    },
    logoutFailure(state: any, action: any) {
      state.success = false;
      state.error = action.payload.message;
    },
    getContentTemplateReq(state: any) {
      state.success = false;
      state.error = "";
      state.pageTemplate = {};
    },
    getContentTemplateSucc(state: any, action: any) {
      const arrToObj = action.payload.data.reduce(
        (obj: object, item: { template_name: string }) =>
          Object.assign(obj, { [item.template_name]: item }),
        {}
      );

      state.success = false;
      state.error = "";
      state.pageTemplate = arrToObj || {};
    },
    getContentTemplateFail(state: any, action: any) {
      state.success = false;
      state.error = action.payload;
      state.pageTemplate = {};
    },
    getCurrencyExchangeReq(state: any) {
      state.exchangeRate = {};
    },
    getCurrencyExchangeSucc(state: any, action: any) {
      state.exchangeRate = {};
    },
    getCurrencyExchangeFail(state: any, action: any) {
      state.exchangeRate = {};
    },
    getUserDataReq(state: any) {
      state.userData = {};
    },
    getUserDataSucc(state: any, action: any) {
      state.userData = action.payload.data;
    },
    getUserDataFail(state: any, action: any) {
      state.userData = {};
    },
    showDialog(state?: any, action?: any) {
      state.modalProps = action?.payload;
      state.showModal = action?.payload?.showModal
        ? action?.payload?.showModal
        : !state.showModal;
    },
    wishListAddRemoveReq(state: any, action: any) {
      state.requesting = true;
      state.success = false;
      state.wishListResponse = {};
      state.wishListSuccess = false;
    },
    wishListAddRemoveSucc(state: any, action: any) {
      if (action.payload?.data?.hotel_id) {
        state.userWishList = [...state.userWishList, action.payload.data];
      } else if (action.payload?.data?._id) {
        state.userWishList = state.userWishList.filter(
          (eachWishList: any) => eachWishList._id !== action.payload.data._id
        );
      }
      state.requesting = false;
      state.wishListSuccess = true;
      state.success = true;
    },
    wishListAddRemoveFail(state: any, action: any) {
      toast.error(action.payload.message, {
        toastId: "error",
      });
      state.requesting = false;
      state.success = false;
      state.wishListResponse = {};
      state.wishListSuccess = false;
    },
    getUserAddedWishListReq(state: any) {
      state.requesting = false;
      state.success = false;
      state.userWishList = [];
    },
    getUserAddedWishListSucc(state: any, action: any) {
      state.requesting = false;
      state.success = true;
      state.userWishList = action.payload?.data?.dataList || [];
    },
    getUserAddedWishListFail(state: any, action: any) {
      state.requesting = false;
      state.success = false;
      state.userWishList = [];
    },
    getEventListReq(state: any) {
      state.requesting = true;
      state.success = false;
    },
    getEventListSucc(state: any, action: any) {
      state.requesting = false;
      state.success = false;
      state.eventList = action.payload?.data?.dataList;
    },
    getEventListFail(state: any, action: any) {
      state.requesting = false;
      state.success = false;
    },
    getHotelRecentSearchReq(state: any) {
      // state.recentHotelSearch = [];
    },
    getHotelRecentSearchSucc(state: any, action: any) {
      state.recentHotelSearch = action.payload.recentSearch || [];
    },
    getHotelRecentSearchFail(state: any, action: any) {
      state.recentHotelSearch = [];
    },
    getFlightRecentSearchReq(state: any) {
      // state.recentFlightSearch = [];
    },
    getFlightRecentSearchSucc(state: any, action: any) {
      state.recentFlightSearch = action.payload.recentSearch || [];
    },
    getFlightRecentSearchFail(state: any, action: any) {
      state.recentFlightSearch = [];
    },
    clearRedux(state: any) {
      // state.recentHotelSearch = [];
      // state.recentFlightSearch = [];
      state.showModal = false;
      state.modalProps = {};
      state.wishListSuccess = false;
    },
    clearRecentSearchRedux(state: any) {
      state.recentHotelSearch = [];
      state.recentFlightSearch = [];
    },
  },
});

export const {
  actions: initializeActions,
  reducer,
  name: sliceKey,
} = initializeSlice;
