import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { ContainerState } from "./types";

export const initialState: ContainerState = {
  requesting: false,
  success: false,
  error: null,
  userInfo: "",
  loginData: {},
  accessToken: "",
  responseMessage: "",
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    loginRequest(state, action: PayloadAction<any>) {
      state.requesting = true;
      state.error = "";
      state.userInfo = "";
      state.loginData = action.payload;
      state.success = false;
    },
    socialLoginRequest(state, action: PayloadAction<any>) {
      state.requesting = true;
      state.error = "";
      state.userInfo = "";
      state.success = false;
    },
    loginSuccess(state, action: any) {
      const deserializedResp = action.payload.data;
      const responseData = deserializedResp.token;
      localStorage.setItem("tokenBased", responseData);
      localStorage.removeItem("guest_token");
      document.cookie = `tokenBased=${responseData};max-age=86400; path=/`;
      document.cookie = `guest_token=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;
      // state.userInfo = responseData;
      state.requesting = false;
      state.error = null;
      state.success = true;
    },
    loginFailure(state, action: any) {
      const deserializedResp = action?.payload?.data?.data;
      const responseData = deserializedResp?.msg || action.payload.message;
      state.userInfo = "";
      state.error = responseData;
      state.requesting = false;
      state.success = false;
    },
    forgotPasswordReq(state, action: any) {
      state.requesting = true;
      state.error = null;
      state.success = false;
      state.responseMessage = "";
    },
    forgotPasswordSucc(state, action: any) {
      state.requesting = false;
      state.error = null;
      state.success = true;
      state.responseMessage = action.payload.message;
    },
    forgotPasswordFail(state, action: any) {
      state.requesting = false;
      state.error = action.payload.message;
      state.success = false;
      state.responseMessage = "";
    },
    setPasswordReq(state: any, action: any) {
      state.requesting = true;
      state.error = action.payload.message;
      state.success = false;
    },
    setPasswordSucc(state: any, action: any) {
      const passwordSet = action.payload.data;
      const responseData = passwordSet.token;
      localStorage.setItem("tokenBased", responseData);
      localStorage.removeItem("guest_token");
      document.cookie = `tokenBased=${responseData};max-age=86400; path=/`;
      document.cookie = `guest_token=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;
      state.requesting = false;
      state.error = "";
      state.success = false;
    },
    setPasswordFail(state: any, action: any) {
      state.requesting = false;
      state.error = action.payload.message;
      state.success = false;
    },

    verifyTfaCodeReq(state: any, action: any) {
      state.requesting = true;
      state.error = null;
      state.success = true;
    },
    verifyTfaCodeSucc(state: any, action: any) {
      const verifiedMultiAuth = action.payload.data;
      const responseData = verifiedMultiAuth.token;
      localStorage.setItem("tokenBased", responseData);
      localStorage.removeItem("guest_token");
      document.cookie = `tokenBased=${responseData};max-age=86400; path=/`;
      document.cookie = `guest_token=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;
      state.requesting = false;
      state.error = null;
      state.success = true;
    },
    verifyTfaCodeFail(state: any, action: any) {
      state.requesting = false;
      state.error = action.payload.message;
      state.success = true;
    },
    clearLoginState(state) {
      state.requesting = false;
      state.error = null;
      state.loginData = {};
      state.success = false;
    },
  },
});

export const { actions: loginActions, reducer, name: sliceKey } = loginSlice;
