export const defaultRequired: any = [
    { name: 'gender', message: 'Select One' },
    { name: 'first_name', message: 'Enter First Name' },
    { name: 'last_name', message: 'Enter Last Name' },
    { name: 'email', message: 'Enter Email', afterData: true },
    { name: 'address_country', message: 'Select One' },
    { name: 'password', message: 'Enter Password' },
    { name: 'agree_terms_condition', message: 'Please check this one' },
    { name: 'reCaptcha', message: 'Please check this one' }
]

export const prefixData: any = [
    { value: 'mr', text: "Mr" },
    { value: 'mrs', text: 'Mrs' },
    { value: 'miss', text: 'Miss' }
]