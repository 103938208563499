import { decodedData } from "utils/encodeDecode";
import { BASE_URL, URL } from "constants/constants";

export function ddlMapper(
  srcArr = [],
  keyTextValue: string | any[],
  replaceKeys?: any,
  addKey: any = false
) {
  const arr = [];
  if (keyTextValue.length > 0) {
    const [key, text, value] = keyTextValue;
    if (srcArr.length > 0) {
      for (let idx = 0; idx < srcArr.length; idx += 1) {
        arr.push({
          [key]: srcArr[idx][key] || replaceKeys || `unique_value_${idx}`,
          text: srcArr[idx][text],
          value: srcArr[idx][value].includes("</b>")
            ? srcArr[idx][value].replace(/(<([^>]+)>)/gi, "")
            : srcArr[idx][value],
          ...((addKey && { original: srcArr[idx]?.original || "" }) as {}),
        });
      }
    }
  }
  return arr;
}

export const addyearInDate = (value: number, date = new Date()) => {
  return new Date(
    new Date(date).setFullYear(new Date(date).getFullYear() + value)
  );
};
export const subtractYearInDate = (value: number, date = new Date()) => {
  return new Date(
    new Date(date).setFullYear(new Date(date).getFullYear() - value)
  );
};

export const addDayInDate = (value: number, date = new Date()) => {
  return new Date(new Date(date).setDate(date.getDate() + value));
};

export const subtractDayInDate = (value: number, date = new Date()) => {
  return new Date(new Date(date).setDate(date.getDate() - value));
};

export const noOfDuration = (endDate: any, startDate: any) => {
  if (endDate && startDate) {
    endDate = new Date(endDate);
    startDate = new Date(startDate);
    const diff =
      parseInt((endDate - startDate) / (1000 * 60 * 60 * 24), 10) || "";
    return diff;
  } else {
    return "";
  }
};

export const dateFormatter = (date: any, format = "m/d/y") => {
  if (
    (date && typeof date === "object") ||
    (date && typeof date === "string" && !date.includes("-"))
  ) {
    switch (format) {
      case "m/d/y": // 7/14/2020
        return new Date(Number(date)).toLocaleDateString("en-us");
      case "m d": // JUL 14
        return new Date(Number(date)).toLocaleDateString("en-us", {
          month: "short",
          day: "numeric",
        });
      case "m y": // JUL 2022
        return new Date(Number(date)).toLocaleDateString("en-us", {
          month: "short",
          year: "numeric",
        });
      case "d m": // 14 JUL
        return new Date(Number(date)).toLocaleDateString("US", {
          month: "short",
          day: "numeric",
        });
      case "d m y": // 14 JUL 2020
        return new Date(Number(date)).toLocaleDateString("US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });

      case "y-m-d": // 2020-07-14
        // return new Date(Number(date)).toLocaleDateString("en-CA");
        // return new Date(Number(date)).toISOString().split("T", 1)[0];
        const tempDate = new Date(Number(date))
          .toLocaleDateString("en-GB")
          .split("/");
        return tempDate.reverse().join("-");
      case "m-d-y": //7-14-2020
        return new Date(Number(date))
          .toLocaleDateString("en-us")
          .replaceAll("/", "-");
      case "y-m-d hh:mm:ss": // 2021-04-12 16:56:00
        return new Date(Number(date))
          .toLocaleDateString("en-CA", {
            hour12: true,
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })
          .replace(",", "");
      case "w-d-m-y": // Thur, 20 Dec 2023
        return new Date(Number(date)).toLocaleDateString("en-CA", {
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
        });
      case "am": //03:00:00 AM || PM
        return new Date(Number(date)).toLocaleTimeString("en-US");
      case "hh:mm:ss": //03:00:00
        return new Date(Number(date)).toLocaleTimeString("it-IT");
    }
  } else return date;
  return null;
};

export function trimSpace(data: any) {
  const wsRegex = /^\s+|\s+$/g; // Change this line
  return typeof data === "string" ? data.replace(wsRegex, "") : data;
}

export const queryFunction = (
  data: any,
  exclude = [],
  assignWithoutFilter = []
) => {
  const defaultExlcude = [...exclude, "search", "orderBy"];
  let filterQuery = "";
  const removeFilterString = [
    ...assignWithoutFilter,
    "currentPage",
    "perPage",
    "page",
  ];
  if (data) {
    Object.keys(data).map(
      // eslint-disable-next-line no-return-assign
      (x) => {
        if (data[x] && !defaultExlcude.includes(x)) {
          filterQuery += `${filterQuery.includes("?") ? "&" : "?"}${
            removeFilterString.includes(x)
              ? `${x}=${trimSpace(data[x])}`
              : `${x}=${trimSpace(data[x])}`
          }`;
        }
        if (x === "orderBy" && data[x].field) {
          filterQuery += `${filterQuery.includes("?") ? "&" : "?"}order[${
            data[x].field
          }]=${data[x].direction === "ascending" ? "asc" : "desc"}`;
        }

        return null;
      }
    );
  }
  return filterQuery;
};

export const formatRate = (num: any, noOfPosition: any = 5) => {
  let decimalPosition = noOfPosition;
  if (num > 999) decimalPosition = 2;
  else if (num > 99) decimalPosition = 3;
  else if (num > 9 && num < 99) decimalPosition = 3;
  else decimalPosition = 5;
  if (typeof num !== "number") return "";
  let result = num.toFixed(decimalPosition).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  return result;
};

export const clipper = (data: any, length = 35) => {
  return data && data.length > length
    ? `${data.substring(0, length)}....`
    : data;
};

export const getDesiredValue = (
  cryptoList: any,
  desiredKey: any,
  defaultCurrency: any,
  price?: any
) => {
  let requiredData: any;
  if (cryptoList?.length && defaultCurrency) {
    if (desiredKey === "currency") {
      requiredData = defaultCurrency.toUpperCase();
    } else
      cryptoList.map((eachCrypto: any) => {
        if (defaultCurrency.toUpperCase() === eachCrypto.symbol)
          switch (desiredKey) {
            case "image":
              requiredData = URL.replace(".com/", ".com") + eachCrypto?.logoImg;
              break;
            case "exRate":
              if (eachCrypto.exRate === 0) {
                requiredData = "Loading ... ";
              } else {
                requiredData = formatRate(price / eachCrypto.exRate);
              }
            default:
              return "";
          }
      });
  }
  return requiredData;
};

export const getFiatValue = (key: string, price?: any) => {
  const data: any =
    (typeof window !== "undefined" &&
      localStorage.getItem("fiat") &&
      decodedData(localStorage?.getItem("fiat"))) ||
    {};
  let finalData: any = "";
  switch (key) {
    case "image":
      finalData = data.image;
      break;
    case "symbol":
      finalData = data.symbol;
      break;
    case "exRate":
      finalData = formatRate(price / Number(data.exRate), 2);
      break;
    default:
      finalData = "";
      break;
  }
  return finalData;
};

export const changeStringToCode = (d: any) => {
  const hexDecode = (d: any) => {
    let j;
    let hexes = d.match(/.{1,4}/g) || [];
    let back = "";
    for (j = 0; j < hexes.length; j++) {
      back += String.fromCharCode(parseInt(hexes[j], 16));
    }
    return back;
  };
  d = d.replace("&#x", "");
  d = hexDecode(d);
  return d;
};

export const passwordHelper = (password: any) => {
  let errors = {};

  if (!/[0-9]/.test(password)) {
    errors = Object.assign({}, errors, { numberError: true });
  }
  if (!/[a-z]/.test(password)) {
    errors = Object.assign({}, errors, { lowercaseError: true });
  }
  if (!/[A-Z]/.test(password)) {
    errors = Object.assign({}, errors, { uppercaseError: true });
  }
  if (!/[!@#$&*]/.test(password)) {
    errors = Object.assign({}, errors, { specialCharactersError: true });
  }
  if (!/.{8}/.test(password)) {
    errors = Object.assign({}, errors, { lengthError: true });
  }

  return errors;
};

export const timeAgo = (time: any) => {
  switch (typeof time) {
    case "number":
      break;
    case "string":
      time = +new Date(time);
      break;
    case "object":
      if (time.constructor === Date) time = time.getTime();
      break;
    default:
      time = +new Date();
  }
  const time_formats = [
    [60, "seconds", 1], // 60
    [120, "1 minute ago", "1 minute from now"], // 60*2
    [3600, "minutes", 60], // 60*60, 60
    [7200, "1 hour ago", "1 hour from now"], // 60*60*2
    [86400, "hours", 3600], // 60*60*24, 60*60
    [172800, "Yesterday", "Tomorrow"], // 60*60*24*2
    [604800, "days", 86400], // 60*60*24*7, 60*60*24
    [1209600, "Last week", "Next week"], // 60*60*24*7*4*2
    [2419200, "weeks", 604800], // 60*60*24*7*4, 60*60*24*7
    [4838400, "Last month", "Next month"], // 60*60*24*7*4*2
    [29030400, "months", 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
    [58060800, "Last year", "Next year"], // 60*60*24*7*4*12*2
    [2903040000, "years", 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
    [5806080000, "Last century", "Next century"], // 60*60*24*7*4*12*100*2
    [58060800000, "centuries", 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
  ];
  let seconds = (+new Date() - time) / 1000;
  let token = "ago";
  let list_choice = 1;

  // eslint-disable-next-line eqeqeq
  if (seconds == 0) {
    return "Just now";
  }
  if (seconds < 0) {
    seconds = Math.abs(seconds);
    token = "from now";
    list_choice = 2;
  }
  let i = 0;
  let format;
  while ((format = time_formats[i++]))
    if (seconds < format[0]) {
      if (typeof format[2] === "string") return format[list_choice];
      return `${Math.floor(seconds / format[2])} ${format[1]} ${token}`;
    }
  return time;
};

export const camelToSnake = (camelCaseString: string) => {
  return camelCaseString.replace(/[A-Z]/g, (match, offset) => {
    return (offset ? "_" : "") + match.toLowerCase();
  });
};
