import API from "utils/apiHelper";
// import Initialize from "../Initialize";
import type { AppProps } from "next/app";
import Context from "../contexts/context";
import { wrapper } from "store/configureStore";
import { initalizeSaga } from "../Initialize/saga";
import { useSelector, useDispatch } from "react-redux";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { sliceKey, reducer, initializeActions } from "../Initialize/slice";
import {
  selectFiatList,
  selectUserData,
  selectModaProps,
  selectCryptoList,
  selectShowDialog,
  selectPageTemplate,
  selectDefaultCurrency,
  selectHotelCryptoData,
} from "../Initialize/selectors";
import { getCookie, isLogin } from "utils/authorizationHelper";
import { Provider } from "react-redux";
import "styles/nprogress.css";
import CustomToaster from "comp/Toaster";
import "styles/style.scss";
import React from "react";
import { tokenId } from "utils/authorizationHelper";
import ErrorBoundary from "comp/ErrorBoundary";
import useShowRouteChangeProgress from "customHooks/useShowRouteChangeProgress";
import LoginModal from "comp/ModalDialog";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { ThemeContextProvider } from "../contexts/themeContext";
// const LoginModal = dynamic(() => import("comp/ModalDialog"));
// const LoginModal = dynamic(() => import("comp/ModalDialog"), {
//   ssr: false,
//   loading: () => <div>Loading...</div>,
// });

declare global {
  interface Window {
    console: any;
  }
}

// (window as any).MyNamespace;

export const DARK_MODE_LOCAL_KEY = "xt-web-dark";

const App = ({
  Component,
  pageProps,
  userLogin,
  darkModeCookie,
}: // rest
  {
    Component: any;
    pageProps: any;
    userLogin: any;
    darkModeCookie: boolean,
  }) => {

  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: initalizeSaga });
  const dispatch = useDispatch();
  const fiatList = useSelector(selectFiatList);
  const userDetails = useSelector(selectUserData);
  const modalProps = useSelector(selectModaProps);
  const showModal = useSelector(selectShowDialog);
  const cryptoListData = useSelector(selectCryptoList);
  const pageTemplate = useSelector(selectPageTemplate);
  const hotelCryptoData = useSelector(selectHotelCryptoData);
  const defaultCurrency = useSelector(selectDefaultCurrency);
  const [currency, setcurrency] = React.useState("btc");

  React.useEffect(() => {
    setcurrency(defaultCurrency);
  }, [defaultCurrency]);
  if (process.env.MY_ENV === "prod" && typeof window !== "undefined") {
    const noop = () => { };
    [
      "assert",
      "clear",
      "count",
      "debug",
      "dir",
      "dirxml",
      "error",
      "exception",
      "group",
      "groupCollapsed",
      "groupEnd",
      "info",
      "log",
      "markTimeline",
      "profile",
      "profileEnd",
      "table",
      "time",
      "timeEnd",
      "timeline",
      "timelineEnd",
      "timeStamp",
      "trace",
      "warn",
      "request",
    ]?.forEach((method) => {
      window.console[method] = noop;
    });
  }

  useShowRouteChangeProgress();
  React.useEffect(() => {
    dispatch(initializeActions.getCryptoListReq());
    dispatch(initializeActions.getContentTemplateReq());
    if (!localStorage.getItem("tokenBased")) {
      document.cookie = `tokenBased=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;
    } else if (tokenId()._id) {
      dispatch(initializeActions.getUserDataReq());
    }
  }, []);

  /*   typeof window !== "undefined" &&
    window?.addEventListener("orientationchange", function () {
      var orientation = window.screen.orientation;

      // Check if the current orientation is landscape
      if (
        orientation.type === "landscape-primary" ||
        orientation.type === "landscape-secondary"
      ) {
        // Prompt the user to rotate back to the desired orientation
        alert("Please rotate your device to portrait mode.");
      }
    }); */

  return (
    <ErrorBoundary>
      <Context.Provider
        value={{
          fiatList: fiatList,
          showModal: showModal,
          userDetails: userDetails,
          defaultCurrency: currency,
          isUserLogin: userLogin || false,
          pageTemplate: pageTemplate || {},
          supportedCurrency: cryptoListData || {},
          hotelCryptoData: hotelCryptoData,
          flightCryptoData: hotelCryptoData,
          showDialog: (payload: any) =>
            dispatch(initializeActions.showDialog(payload)),
          setDefaultCurrency: (value: any) =>
            dispatch(initializeActions.setDeafultCurrency(value)),
          logoutReq: () => dispatch(initializeActions.logoutRequest()) || false,
          fetchExchageRate: () =>
            dispatch(initializeActions.getCurrencyExchangeReq()),
        }}
      >
        <ThemeContextProvider initialMode={darkModeCookie}>
          <Component {...pageProps} />
          <LoginModal {...modalProps} />
          <CustomToaster />
        </ThemeContextProvider>
      </Context.Provider>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-PXN5JVP3"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript>
    </ErrorBoundary>
  );
};

function MyApp({ Component, pageProps, ...rest }: AppProps) {
  const { store, props } = wrapper.useWrappedStore(rest);
  return (
    <Provider store={store}>
      <App
        Component={Component}
        pageProps={pageProps}
        userLogin={rest?.userLogin || false}
        darkModeCookie={rest?.darkModeCookie}
      // rest={rest}
      />
    </Provider>
  );
}

MyApp.getInitialProps = async ({ Component, ctx }) => {
  let pageProps = {};
  let userLogin = false;
  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }
  const cookie = ctx.req?.headers?.cookie;
  userLogin = isLogin(cookie) || false;
  const darkModeCookie = getCookie(DARK_MODE_LOCAL_KEY, cookie);
  return {
    pageProps,
    userLogin,
    darkModeCookie: darkModeCookie === "true" ? true : false,
  };
};

export default MyApp;
