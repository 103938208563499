import React, {
  useState,
  useEffect,
  useContext,
  Fragment,
  useLayoutEffect,
} from "react";
import Link from "next/link";
import Image from "next/image";
import Context from "contexts/context";
import { isLogin } from "utils/authorizationHelper";
import { Navbar, Dropdown, Accordion } from "flowbite-react";
import { NextRouter, useRouter } from "next/router";
import { getFiatValue, getDesiredValue } from "utils/helperFunctions";
import { Icon } from "@iconify/react";
import Logo from "image/navbar/XceltripMain.svg";
import whiteLogo from "image/navbar/XceltripMain-dark.svg";
import DefaultImage from "image/image.svg";
import Cross from "image/navbar/cross.svg";
import XcelPay from "image/navbar/xcelpay-new.png";
import useComponentVisible from "src/customHooks/useComponentVisible";

import Gplay from "image/navbar/gplay.png";
import appStore from "image/navbar/appstore.png";
import QRcode from "image/navbar/xcelpayqr.png";
import { URL } from "constants/constants";
import MobileSideNavigationHeader from "./sideBarMobile";
import { useTheme } from "../../contexts/themeContext";

import { DOCUMENT_URL } from "constants/constants";

interface HeaderProps {}

const Header = (props: HeaderProps) => {
  const router = useRouter();
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false, true);
  const contextData: {
    logoutReq?: any;
    showDialog: any;
    hotelCryptoData: any;
    supportedCurrency: { hotel: {} };
    defaultCurrency: any;
    userDetails: any;
    setDefaultCurrency: any;
  } = useContext(Context);
  const { darkMode, setDarkMode } = useTheme();
  const {
    isUserLogin = false,
    logoutReq = false,
    showDialog,
    hotelCryptoData,
    userDetails,
    defaultCurrency,
  }: { isUserLogin?: boolean; logoutReq: void; showDialog: void } = contextData;
  const [showProfile, setshowProfile] = useState(isUserLogin);
  const [selectedFiatImg, setselectedFiatImg] = useState("");
  const [isScrolled, setIsScrolled] = useState(false);
  const [showTopBanner, setshowTopBanner] = useState(false);
  const isHomePage = router.pathname === "/"; //white logo
  const isLightModeAndScrolled = !darkMode && isScrolled; //dark logo

  useEffect(() => {
    if (isComponentVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isComponentVisible]);

  useEffect(() => {
    const hideTopBanner = sessionStorage.getItem("hideTopBanner");
    if (router.asPath.includes("?embed=true")) {
      setshowTopBanner(false);
      sessionStorage.setItem("hideTopBanner", "yes");
    } else if (hideTopBanner === "yes") {
      setshowTopBanner(false);
    } else setshowTopBanner(true);

    if (isLogin()) {
      setshowProfile(true);
    }
  }, []);

  useEffect(() => {
    if (userDetails?._id) {
      setshowProfile(true);
    }
  }, [userDetails]);

  useEffect(() => {
    if (!localStorage.getItem("tokenBased")) {
      setshowProfile(false);
    }
  }, [typeof window !== "undefined" && localStorage.getItem("tokenBased")]);

  useEffect(() => {
    if (localStorage.getItem("fiat")) {
      setselectedFiatImg(getFiatValue("image"));
    }
  }, [typeof window !== "undefined" && localStorage.getItem("fiat")]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const handleCurrencies = (defaultIndex = 0) => {
    const payload = {
      title: "Currencies",
      size: "5xl",
      mapComp: "currFiatList",
      addClassName: "reactmodal-modify ",
      defaultIndex: defaultIndex,
    };
    showDialog(payload);
  };

  const SignupButtons = showProfile ? (
    <>
      <Link href={"/my-list"} className={`!m-0 !text-left`}>
        My Favorites
      </Link>
      <Dropdown
        className={`ml-0`}
        label={
          <>
            {userDetails?.image_name ? (
              <Image
                width={50}
                height={50}
                alt="user-profile"
                src={DOCUMENT_URL + userDetails.image_name}
                className="!static w-6 h-6  rounded-full object-cover"
              />
            ) : (
              <p className="gap-1 m-0 items-centerflex">
                <Icon
                  className="w-6 h-6"
                  icon={`iconamoon:profile-circle-thin`}
                />{" "}
              </p>
            )}
          </>
        }
        inline={true}
        className="!text-sm"
      >
        <Dropdown.Item>
          <Link href="/dashboard/profile">Profile</Link>
        </Dropdown.Item>
        {userDetails?.user_role?.includes("imp") && (
          <Dropdown.Item>
            <Link href="/imp-dashboard/dashboard">IMP Dashboard</Link>
          </Dropdown.Item>
        )}
        <Dropdown.Item onClick={logoutReq}>Logout</Dropdown.Item>
      </Dropdown>
    </>
  ) : (
    <React.Fragment>
      {router.pathname !== "/login" && (
        <div>
          <button
            className={` hover:bg-white/20 !ml-0 text-sm py-2 px-4 !rounded-lg z-[3] cursor-default text-normal
            ${
              isScrolled && isHomePage
                ? "text-black dark:text-dark-text-primary"
                : "text-black dark:text-white lg:text-white"
            } `}
          >
            <span
              className="cursor-pointer "
              onClick={() => {
                showDialog({
                  showHeader: false,
                  addClassName: "reactmodal-modify-2",
                });
                setIsComponentVisible(false);
              }}
            >
              Login
            </span>
          </button>
        </div>
      )}
      {router.pathname !== "/sign-up" && (
        <Navbar.Link
          href="#"
          className=" cursor-pointer border-none !p-0"
          onClick={() => router.push("/sign-up")}
        >
          <button
            className={`${
              isScrolled && isHomePage
                ? " bg-newPrimary-100  text-white   "
                : "text-black bg-white  "
            } !ml-0 text-sm py-[6px] px-4 !rounded-md z-[3] hover:bg-newPrimary-100 hover:text-white `}
          >
            <span>Register</span>
          </button>
        </Navbar.Link>
      )}
    </React.Fragment>
  );

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;

      if (position > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navClass =
    isScrolled && isHomePage
      ? "!bg-white/70  dark:!bg-primary-800/40   !shadow-sm dark:shadow-lg   top-0  before:absolute before:inset-0 before:w-full before:h-full before:z-[-1] before:backdrop-blur-[10px]    "
      : "";

  return (
    <>
      {showTopBanner && (
        <div className="mobile__app !rounded-b-lg shadow-m1 not-printable">
          <div className="flex items-center">
            {/* <Image
              src={Cross}
              alt="close button"
              onClick={() => {
                setshowTopBanner(!showTopBanner);
                sessionStorage.setItem("hideTopBanner", "yes");
              }}
            /> */}
            <Icon
              icon={`iconamoon:close-fill`}
              className={`w-6 h-6 mr-1 dark:text-dark-text-primary`}
              onClick={() => {
                setshowTopBanner(!showTopBanner);
                sessionStorage.setItem("hideTopBanner", "yes");
              }}
            />
            <div className="">
              <Image
                fill
                className="!static !h-6 sm:!h-8 !w-6"
                src={XcelPay}
                alt="Icon logo"
              />
            </div>
            <div className="ml-2">
              <div className="font-semibold uppercase text-vs sm:text-xs text-newPrimary-100">
                Download XcelPay App
              </div>
              <div className="text-xs sm:text-sm dark:text-dark-text-primary">
                Goodbye Fiats, Book with Crypto
              </div>
            </div>
            <div className="flex-1 text-right">
              <a href="https://onelink.to/xcelpay">
                <button className=" text-white !rounded-full font-semibold text-vs sm:text-xs sm:px-3 px-[8px] py-1 bg-newPrimary-100">
                  Use App
                </button>
              </a>
            </div>
          </div>
        </div>
      )}

      <Navbar
        className={`${
          isHomePage
            ? "fixed transition-all ease-out !bg-transparent !shadow-none  rounded-b-xl "
            : "gradientMain dark:bg-gradient-to-b dark:from-primary-800/40 dark:to-primary-800/40 "
        } w-full   ${navClass} 
         z-[2]  border-none not-printable dark:bg-transparent`}
      >
        <Link href="/">
          <Image
            src={
              isHomePage
                ? isLightModeAndScrolled
                  ? Logo
                  : whiteLogo
                : whiteLogo
            }
            alt="xceltrip"
            // placeholder="blur"
            className="mr-3 self-center w-28 lg:w-44 h-[54px] transition-all ease-in-out "
          />
        </Link>

        {/* <----------------mobile navbar and fiat tab-------------> */}
        <div className="flex gap-4 md:hidden">
          <div className="my-auto md:hidden showfiat-mobile">
            <FiatCurrency
              isScrolled={isScrolled}
              isHomePage={isHomePage}
              handleCurrencies={handleCurrencies}
              hotelCryptoData={hotelCryptoData}
              defaultCurrency={defaultCurrency}
              router={router}
              selectedFiatImg={selectedFiatImg}
            />
          </div>

          <div onClick={() => setIsComponentVisible(!isComponentVisible)}>
            <Icon
              icon="ci:menu-alt-03"
              className={`w-7 h-7 dark:text-dark-text-primary`}
            />
          </div>
        </div>

        <Navbar.Collapse
          className={`${isComponentVisible ? " block " : ""} ${
            isHomePage
              ? "md:bg-none bg-gradient-to-b from-slate-900 to-slate-800 md:text-black text-white !font-normal"
              : "bg-gradient-to-b from-slate-900 to-slate-800 md:bg-none  "
          } nav-fix ] `}
        >
          {/* <------------booking dropdow---> */}

          {/* <----desktop currency tab--------------> */}
          <div className="hidden !ml-0 md:block">
            <FiatCurrency
              isScrolled={isScrolled}
              handleCurrencies={handleCurrencies}
              hotelCryptoData={hotelCryptoData}
              defaultCurrency={defaultCurrency}
              router={router}
              selectedFiatImg={selectedFiatImg}
            />
          </div>

          {/* <---- Login and register button--------------> */}
          {SignupButtons}

          {/* {Download btn} */}
          <div className="relative items-center justify-center hidden w-10 h-10 mr-4 transition-all cursor-pointer qr__block menu-button lg:flex hover:border-slate-400">
            <Icon
              className={`w-5 h-5 ${
                isScrolled && isHomePage
                  ? "text-black dark:text-dark-text-primary"
                  : "text-white"
              }`}
              icon="solar:download-minimalistic-outline"
            />
            <div className="qrcode__box qrcode__box-header ">
              <p
                className={`text-center mb-1 text-xs pt-2 p-1 text-black dark:text-dark-text-primary`}
              >
                Download XcelPay App to get exciting discounts
              </p>
              <a
                href="https://onelink.to/xcelpay"
                target="_blank"
                rel="noreferrer"
              >
                <Image
                  className="img-fluid w-[110px] mx-auto"
                  src={QRcode}
                  alt="qr"
                />
              </a>
              <div className="flex gap-1 py-2">
                <a
                  href="https://apps.apple.com/us/app/xcelpay-secure-crypto-wallet/id1461215417"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    className="w-24 h-8 mx-auto mb-1 "
                    src={appStore}
                    alt="appstore"
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.XcelTrip.XcelPay&hl=en&gl=US"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    className="w-24 h-8 mx-auto mb-1"
                    src={Gplay}
                    alt="playstore"
                  />
                </a>
              </div>
            </div>
          </div>

          {/* {list crypto} */}
          <div>
            <ListCrypto isScrolled={isScrolled} isHomePage={isHomePage} />
          </div>
          <div>
            <button
              onClick={toggleDarkMode}
              className="flex items-center justify-center p-2.5 border border-slate-200 rounded-full dark:border-white/50"
            >
              {darkMode ? (
                <Icon
                  icon="icon-park-solid:dark-mode"
                  className="w-4 h-4 text-primary-200"
                />
              ) : (
                <Icon
                  icon="ic:round-dark-mode"
                  className="w-4 h-4 text-white "
                />
              )}
            </button>
          </div>
          {/* {NewUserBtn} */}
        </Navbar.Collapse>

        {/* <-----------new slide--> */}
        <MobileSideNavigationHeader
          passRef={ref}
          SignupButtons={SignupButtons}
          isComponentVisible={isComponentVisible}
          setIsComponentVisible={setIsComponentVisible}
          isUserLogin={isUserLogin}
        />
      </Navbar>
    </>
  );
};

const ListCrypto = ({
  isScrolled,
  isHomePage,
}: {
  isScrolled: boolean;
  isHomePage: boolean;
}) => (
  <div className=" LC-dropdown">
    <Dropdown
      as="button"
      title="button-title"
      label={
        <span className="block LC-dropdown__more">
          <Icon
            icon="solar:menu-dots-outline"
            className={`${
              isScrolled && isHomePage
                ? "text-black dark:text-dark-text-primary"
                : "text-white  "
            } w-6 h-6 `}
          ></Icon>
        </span>
      }
      className="p-2 px-4 rounded-lg list-token dark:bg-dark-bg-primary"
    >
      <ul className="!text-sm">
        <li className="pb-2 border-b cursor-pointer hover:text-newText-100">
          {" "}
          <Link href="/my-bookings"> My Bookings</Link>
        </li>
        <li className="pt-2 pb-0 cursor-pointer hover:text-newText-100">
          <Link href={`/listyourtoken`}>+ List Your Token </Link>{" "}
        </li>
      </ul>
    </Dropdown>
  </div>
);

const FiatCurrency = ({
  isScrolled,
  isHomePage,
  handleCurrencies,
  hotelCryptoData,
  defaultCurrency,
  router,
  selectedFiatImg,
}: {
  isScrolled: boolean;
  isHomePage?: boolean;
  handleCurrencies: any;
  hotelCryptoData: any;
  defaultCurrency: any;
  router: NextRouter;
  selectedFiatImg: any;
}) => (
  <div
    className={`text-base  ${
      isScrolled
        ? "border-black text-white dark:border-dark-text-primary"
        : " border-white"
    }  
    border group hover:border-gray-200 backdrop-blur-sm py-[6px] px-4  rounded-full `}
  >
    <div className="flex items-center gap-2 cursor-pointer">
      <div className="flex items-center gap-1 ">
        <React.Fragment>
          <Image
            fill
            // placeholder="blur"
            // blurDataURL={shimmerEffect}
            onClick={() => handleCurrencies()}
            src={
              hotelCryptoData?.data?.length
                ? getDesiredValue(
                    hotelCryptoData.data,
                    "image",
                    defaultCurrency
                  )
                : DefaultImage
            }
            alt={"default crypto"}
            className="!w-4 !h-4 !static rounded-full  "
          />
          <button
            onClick={() => handleCurrencies()}
            // ${router.pathname == "/" ? "" : "text-white"}
            className={` 
              ${
                isScrolled && isHomePage
                  ? "text-black dark:text-dark-text-primary"
                  : " text-white"
              } text-sm pt-[2px] mr-[2.5px] `}
          >
            {defaultCurrency?.toUpperCase() || "BTC"}
          </button>
        </React.Fragment>
        <div
          className={`h-[12px] w-[1px] group-hover:bg-none ${
            isScrolled && isHomePage
              ? "bg-black dark:bg-dark-text-primary "
              : "bg-[rgba(255,255,255,1)] "
          } `}
        ></div>
        <div className="" onClick={() => handleCurrencies(1)}>
          {selectedFiatImg ? (
            <Image
              fill
              unoptimized={true}
              alt="fiat Currency"
              // placeholder="blur"
              // blurDataURL={shimmerEffect}
              className="!w-4 !h-4 rounded-full ml-[2.5px] !static  !object-fill"
              src={
                selectedFiatImg.includes("https")
                  ? selectedFiatImg
                  : URL.replace(".com/", ".com") + selectedFiatImg
              }
            />
          ) : (
            <Image
              fill
              alt="fiat Currency"
              className="!w-4 !h-4 rounded-full !static "
              src={DefaultImage}
            />
          )}
        </div>
      </div>
    </div>
  </div>
);

export default Header;
