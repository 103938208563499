import Router from "next/router";

export const authorizationToken = () => {
  return getCookie("tokenBased", document.cookie);
};

export const tokenId = (jwtData = document.cookie) => {
  try {
    const token = getCookie("tokenBased", jwtData);
    var decoded = JSON.parse(atob(token.split(".")[1]));
    if (decoded.user) {
      return decoded.user;
    }
    return "";
  } catch (error) {
    return "";
  }
};
export const isLogin = (cookieValue: String = "") => {
  let status = false;
  if (typeof window === "undefined" && cookieValue && tokenId(cookieValue)) {
    return true;
  } else if (typeof window !== "undefined" && tokenId()) {
    return true;
  } else {
    return status;
  }
};

export const getCurrency = (cookie: string) => {
  try {
    const token = getCookie("currency", cookie);
    return token || "btc";
  } catch (error) {
    return "";
  }
};

export const getCookie = (cname: string, cookie: string) => {
  var name = cname + "=";
  var ca = cookie?.split(";");
  if (ca) {
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
  }
  return "";
};

export const localStorageToken = () => {
  if (typeof window !== "undefined" && localStorage.getItem("tokenBased")) {
    return (document.cookie = `tokenBased=${localStorage.getItem(
      "tokenBased"
    )}; path=/`);
  } else if (
    typeof window !== "undefined" &&
    !localStorage.getItem("tokenBased")
  ) {
    return (document.cookie = `tokenBased=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`);
  } else return "";
};

export const finalizedCookie = (cname: string, cookie: string) => {
  return getCookie(cname, cookie) || localStorageToken();
};

export async function myAuthorizationHelper(
  url: RequestInfo | URL,
  ctx: { req: { headers: { cookie: any } }; res: any },
  redirectToOtherPage = "",
  ignore = false
) {
  const getCookiesToken = finalizedCookie(
    "tokenBased",
    ctx.req?.headers?.cookie
  );
  const cookie = ctx.req?.headers && getCookiesToken;
  let decoded;
  try {
    decoded = (cookie && JSON.parse(atob(cookie.split(".")[1]))) || {
      user: { id: "adc" },
    };
  } catch (error) {
    decoded = { user: { _id: "adc" } };
  }
  const resp = await fetch(url + decoded.user._id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: cookie,
    },
  });

  console.log(
    resp.status,
    "<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<cookie>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",
    url + decoded.user._id
  );
  // console.log(resp.status, !ctx.res, ">>>><<<<<<<<<>>>>>");

  if (redirectToOtherPage) {
    if (resp.status === 200 && !ctx.req) {
      // console.log('object>>>>>>>>>>>>>>>>>>>>>>>>')
      Router.push(`/${redirectToOtherPage}`);
      return {};
    }
    if (resp.status === 200 && ctx.req) {
      // console.log('object<<<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>')
      return {
        redirect: {
          destination: `/${redirectToOtherPage}`,
          statusCode: 307,
          // permanent: false,
        },
      };
    }
  }
  if (!ignore && resp.status === 401 && !ctx.req && !redirectToOtherPage) {
    Router.replace("/login");
    return {};
  }
  if (!ignore && resp.status === 401) {
    if (ctx.res) {
      return {
        redirect: {
          destination: "/login",
          statusCode: 307,
          // permanent: false,
        },
      };
    }
  }

  const json = await resp.json();
  // console.log(json, "json<<<<<<<<<<<<<<<");
  return {
    props: { data: json.data, cookie },
  };
}

// call only in client side if required
export const redirectToLogin = () => {
  if (
    !localStorage.getItem("tokenBased") &&
    !localStorage.getItem("guest_token")
  ) {
    Router.push("/login");
  }
};
``;
