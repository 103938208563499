import React from "react";
import Router from "next/router";
import NProgress from "nprogress";
import { isMobile } from "react-device-detect";
interface Props {
  showToast?: any;
}

const useShowRouteChangeProgress = () => {

  const [showLoader, setshowLoader] = React.useState<any>(false);
  React.useEffect(() => {
    NProgress.configure({
      showSpinner: true,
    });
    const start = () => {
      setshowLoader(true);
      NProgress.start();
    };
    const end = () => {
      setshowLoader(false);
      NProgress.done();
    };
    Router.events.on("routeChangeStart", start);
    Router.events.on("routeChangeComplete", end);
    Router.events.on("routeChangeError", end);
    return () => {
      Router.events.off("routeChangeStart", start);
      Router.events.off("routeChangeComplete", end);
      Router.events.off("routeChangeError", end);
    };
  }, []);

  return [showLoader];
};

export default useShowRouteChangeProgress;
