import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ContainerState } from './types';
import { ddlMapper } from 'utils/helperFunctions'

export const initialState: ContainerState = {
    requesting: false,
    success: false,
    error: null,
    successMessage: '',
    loginData: {},
    accessToken: '',
    countryList: [],
};

const loginSlice = createSlice({
    name: 'register',
    initialState,
    reducers: {
        getAllCountryReq(state: any) {
            state.requesting = true;
            state.error = null;
            state.successMessage = '';
            state.success = false;
            state.countryList = []
        },
        getAllCountrySucc(state: any, action: any) {
            const test = ddlMapper(action.payload.data, ['id', 'name', 'id']);
            state.countryList = test;
            state.requesting = false;
            state.error = null;
            state.success = true;
        },
        getAllCountryFail(state: { successMessage: string; error: any; requesting: boolean; success: boolean; }, action: { payload: { message: any; }; }) {
            const responseData = action.payload.message;
            state.successMessage = '';
            state.error = responseData;
            state.requesting = false;
            state.success = false;
        },
        registerRequest(state: { requesting: boolean; error: null; successMessage: string; success: boolean; }, action: PayloadAction<any>) {
            state.requesting = true;
            state.error = null;
            state.successMessage = '';
            state.success = false;
        },
        socialLoginRequest(state: { requesting: boolean; error: null; successMessage: string; success: boolean; }, action: PayloadAction<any>) {
            state.requesting = true;
            state.error = null;
            state.successMessage = '';
            state.success = false;
        },
        registerSuccess(state: { successMessage: any; requesting: boolean; error: null; success: boolean; }, action: any) {
            const deserializedResp =
                action.payload;
            if (deserializedResp.data.token) {
                const token=deserializedResp.data.token
                localStorage.setItem('tokenBased', token);
                localStorage.removeItem('guest_token')
                document.cookie = `tokenBased=${token};max-age=86400; path=/`;
                document.cookie = `guest_token=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;
            }
            state.successMessage = deserializedResp.message;
            state.requesting = false;
            state.error = null;
            state.success = true;
        },
        registerFailure(state: { successMessage: string; error: any; requesting: boolean; success: boolean; }, action: any) {
            const deserializedResp = action?.payload?.data?.data;
            const responseData = deserializedResp?.msg || action.payload.message;
            state.successMessage = '';
            state.error = responseData;
            state.requesting = false;
            state.success = false;
        },
        clearRegisterState(state: { requesting: boolean; error: null; loginData: {}; success: boolean; successMessage: string; }) {
            state.requesting = false;
            state.error = null;
            state.loginData = {};
            state.success = false;
            state.successMessage = ''
        },
    },
});

export const { actions: registerActions, reducer, name: sliceKey } = loginSlice;
