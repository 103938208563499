import Avt from "image/avt.png";
import React, {
  memo,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
// import dynamic from "next/dynamic";
import Modal from "react-modal";
// import FiatSkeleton from "comp/Skeleton/fiatSkeleton";
// import LoginSkeleton from "comp/Skeleton/loginSkeleton";

import Register from "cont/Sign-Up";
import Login from "cont/OnBoarding";
import CurrencyFiatList from "../CurrencyFiat";
import { useRouter } from "next/router";
// const Register = dynamic(() => import("cont/Sign-Up"));
// const Login = dynamic(() => import("cont/OnBoarding"));
// const CurrencyFiatList = dynamic(() => import("../CurrencyFiat"));

import Context from "contexts/context";
import { Icon } from "@iconify/react";
import { FullscreenOnMobile } from "src/components/FullscreenOnMobile";
import { isMobile } from "react-device-detect";
import useByPassBackButton from "customHooks/useByPassBackButton";

interface Props {
  disableCrossEscPress?: boolean;
  title?: any;
  body?: any;
  size?: any;
  fiatList: any;
  hotelCryptoData: any;
  className?: string;
  showHeader?: any;
  addClassName?: any;
  defaultCurrency: any;
  mapComp?: any;
  setDefaultCurrency: any;
  email: any;
  disablePopInMobile?: boolean;
  defaultIndex?: number;
}
function LoginDailog(props: any) {
  const {
    title,
    body,
    className,
    showHeader = true,
    mapComp = "login",
    addClassName,
    bodyClassName = "",
    email = "",
    disableCrossEscPress = false,
    disablePopInMobile = true,
    defaultIndex,
  } = props;
  const {
    showDialog,
    showModal,
    fiatList,
    hotelCryptoData,
    defaultCurrency,
    setDefaultCurrency,
  }: {
    fiatList: any;
    showDialog: any;
    userDetails: any;
    showModal: boolean;
    hotelCryptoData: any;
    defaultCurrency: any;
    setDefaultCurrency: any;
  } = React.useContext(Context);

  useByPassBackButton({
    isShown: isMobile ? false : showModal,
    setisShown: () => showDialog({ showModal: false }),
  });

  const configComponents: any = {
    currFiatList: (
      <CurrencyFiatList
        fiatList={fiatList}
        showDialog={showDialog}
        defaultIndex={defaultIndex}
        cryptoList={hotelCryptoData}
        defaultCurrency={defaultCurrency}
        setDefaultCurrency={setDefaultCurrency}
      />
    ),
    login: <Login /* showDialog={showDialog} */ userName={email} />,
    forgotPassword: <Login showForgotPassword />,
    passwordSet: <Login showPasswordSetForm email={email} />,
    "2fa": <Login showTwoFactorAuth />,
    register: <Register inPopup />,
  };

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
      /* USED WHEN USER PRESS ESC */
      /* const close = (e: any) => {
        if (e.keyCode === 27) {
          showDialog();
        }
      };
      window.addEventListener("keydown", close); */
    }
    return () => {
      document.body.style.overflow = "unset";
      // window.removeEventListener("keydown", close);
    };
  }, [showModal]);
  const commonTitle: any = (
    <p className="m-0 dark:text-dark-text-primary" id="heading">
      {(showHeader && title) || "Login to your account. "}
    </p>
  );
  const commonBody: any = (
    <>
      {showModal && (
        <>
          <div
            className={`${title === "Currencies" ? "" : " lg:p-0"}
          ${title === "Images" ? "p-4 " : " "}
          `}
          >
            {(body && body) || configComponents[mapComp]}
          </div>
        </>
      )}
    </>
  );

  return (
    <React.Fragment>
      <FullscreenOnMobile
        content={commonBody}
        addClassOnBody="fullscreen_modal_scroll_off"
        className={title === "Currencies" ? "" : "modal-mobile"}
        header={
          <>
            <div className="flex">
              <strong className="mx-auto">{commonTitle} </strong>
              <button
                aria-label="Modal Close"
                className=""
                onClick={() => showDialog()}
              >
                <Icon icon="akar-icons:cross" className="text-red-400" />
              </button>
            </div>
          </>
        }
        setisShown={() => showDialog()}
        isShown={disablePopInMobile && showModal}
      />
      <div className="container hidden mx-auto bg-transparent lg:block">
        <Modal
          isOpen={disablePopInMobile && isMobile ? false : showModal}
          shouldFocusAfterRender
          ariaHideApp={false}
          aria={{
            labelledby: "heading",
            describedby: "full_description",
          }}
          onRequestClose={() => (disableCrossEscPress ? false : showDialog())}
          className={`bg-white py-5  w-[90%] rounded-xl   shadow-md absolute  top-1/2 right-1/2 translate-x-[50%] translate-y-[-50%] p-8 w-fit-content z-[3]
        ${addClassName}`}
        >
          <div className="flex justify-between mb-4">
            {commonTitle}
            {!disableCrossEscPress && (
              <Icon
                onClick={() => showDialog()}
                icon="ic:round-close"
                className="w-6 h-6 cursor-pointer dark:text-white"
              />
            )}
          </div>
          <div className={bodyClassName}>{commonBody}</div>
        </Modal>
      </div>
    </React.Fragment>
  );
}

export default LoginDailog;
